export default {
  account: {
    branding: {
      logoAlt: 'Company Logo',
      noLogoAlt: 'Upload Your Logo',
      deleteLogoModal: {
        confirmationMessage: 'Are you sure you want to delete your logo?',
        title: 'Delete Logo',
      },
      removeLogo: 'Remove Logo',
      message:
        'Your logo will be used in shared galleries, shared project timelines and Before and After photos. It should be a <strong>.jpg</strong> or <strong>.png</strong> file type and should be at least 300px wide.',
    },
    exports: {
      title: 'Exports',
      projects: {
        exportMessage:
          "Click the button below to generate a CSV containing information on all of your company's projects. We'll email you the export once it's ready.",
        submittedExportMessage:
          'Your export is being generated and will be emailed to you shortly. You can safely navigate away from this page.',
        exportProjects: 'Export Projects',
      },
    },
    integrations: {
      headerTitle: 'Integrations',
      title: 'Manage Integrations',
      noResultsFound: 'No Results Found',
      searchForIntegration: 'Search for an Integration',
      estimatingProposals: 'Estimating/Proposals',
      timeTracking: 'Time Tracking/Scheduling',
      newAndFeatured: 'New and Featured',
      allIntegrations: 'All Integrations',
      connectedIntegrations: 'Connected Integrations',
      trySearchingDifferently:
        'Try searching for different keywords or choosing a different category filter.',
      tooltip: {
        accessibilityLabel: 'More details',
      },
      input: {
        invalid: 'The input is invalid',
      },
      serviceTitan: {
        inputValidatorMessage:
          'Invalid Tenant ID: Refer to the tutorial for help',
        missingTenantId: 'Tenant ID is missing',
        actionRequiredMessage: {
          partOne:
            'Your setup is almost complete! Please enter the Client ID and Client Secret that are associated with your CompanyCam account in ServiceTitan. If you’re having trouble locating them, ',
          clickHere: 'click here',
          partTwo: ' for more detailed instructions on where to find them.',
        },
        pendingMessage: `We've received your Tenant ID and are processing your request. This shouldn't take more than a few minutes. You can refresh this page momentarily or check back in on it later where you can complete the final steps for connecting your ServiceTitan account.\n\nIf you entered your Tenant ID incorrectly, you can click the "Cancel Setup" button in the top right corner of the screen to restart the process.`,
        connectedMessage:
          'The integration is connected. If you need to update your credentials, you will need to disconnect and start the integration connection process over again.',
      },
      shared: {
        cancelSetup: 'Cancel Setup',
        crmFSM: 'CRM/FSM',
        connect: 'Connect',
        connectErrorMessage: 'Error Connecting Integration',
        connectIntegrationName: 'Connect %{integrationName}',
        connected: 'Connected',
        disconnect: 'Disconnect',
        disconnectConfirmationMessage:
          'Are you sure you want to disconnect this integration?',
        disconnectErrorMessage: 'Error Disconnecting Integration',
        disconnectIntegrationName: 'Disconnect %{integrationName}',
        updateErrorMessage: 'Error Updating Integration',
        integrationsLabel: 'Integrations',
        overview: 'Overview',
        provider: 'Provider',
        settings: 'Settings',
        loading: 'Loading Integrations',
        disabled: 'Disabled',
      },
      noConnectedIntegrations: "You don't have any connected integrations.",
      exploreTheFullList:
        'Explore the full list and connect your programs today.',
      integrationSuggestion:
        'Do you use software that you would love to have integrated with CompanyCam?',
      emailUs: 'Email us at',
      help: {
        needHelp: 'Need help integrating %{integrationPartner}?',
        tutorial: 'Check out our tutorial',
      },
      common: {
        allSet: "You're all set!",
        connect: 'Connect Integration',
        creating: 'Creating…',
        deleteIntegration: 'Delete Integration',
        integrate: 'Integrate',
      },
      tutorial: 'Tutorial',
    },
  },
  activity: {
    comment: {
      subnote: 'Guest User cannot see replies.',
    },
    commentContainer: {
      addComment: 'Add a comment…',
    },
    commentList: {
      photoActivity: 'Comments',
    },
    commentPopup: {
      addComment: 'Add a comment',
    },
    tagContainer: {
      manageButton: 'Add Tags',
      updateTags: 'Save Tags',
    },
    tagRecordablesModal: {
      modalTitle: 'Select Tags',
      manageTags: "Manage Your Company's Tags",
      tagPhotos: 'Tag Photos',
      tagSectionPhotos: 'Tag all photos in a section',
      noResultsText: 'No results found',
      selectPlaceholder: 'Find tags…',
      selectOrCreatePlaceholder: 'Find tags or create them…',
    },
    tokenizedInput: {
      mentionSomeoneAriaLabel: 'Mention someone',
      commentsSeenByCollabs:
        'Collaborators with permission will see your comments',
      alertMessage: 'Collaborators will see your comments',
      post: 'Post',
    },
    tokenSuggestions: {
      noMatchingResults: 'No matching results',
    },
  },
  assetFeed: {
    emptyTitle: 'Start Snapping',
    emptyMessage: 'Every photo added by your team will appear here.',
    altText: 'Image of a set of photo thumbnails representing a gallery.',
    emptyState: {
      emptyTitle: 'Start taking pictures in the mobile app.',
      emptyMessage:
        'All photos and videos taken by your team will appear here, instantly.',
      secondaryCta: 'Assign Users',
      emptyFilterList:
        "Sorry, there aren't any photos that fit the selected filters.",
      createProject: 'Create Project',
      emptyStateQRTitle: 'Photos cluttering up your camera roll?',
      emptyStateQRMessage:
        'Scan this QR code to start taking and uploading pictures to CompanyCam.',
      emptyStateInviteTitle: 'Ready to collect photos from your team?',
      emptyStateInviteMessage:
        'Tip: Get the photos out of your team’s camera roll by inviting them to take pictures in CompanyCam.',
      emptyStateInviteButton: 'Invite Users',
    },
    header: {
      title: 'Photos',
    },
    filterRow: {
      uploadPhotos: 'Upload Photos',
      assetTypeFilter: {
        photosOnlyLabel: 'Photos Only',
        videosOnlyLabel: 'Videos Only',
      },
      noResultsLabel: 'No %{filterType} found',
      endDateFilter: {
        placeholder: 'End Date',
      },
      clearAll: 'Clear All',
      groupFilter: {
        searchInput: 'Group Query Term',
      },
      sortedByLabel: 'Sorted By Recent Activity',
      projectFilter: {
        searchInput: 'Projects Query Term',
      },
      startDateFilter: {
        placeholder: 'Start Date',
      },
      tagFilter: {
        loading: 'Loading tags...',
        searchInput: 'Tag Query Term',
      },
      userFilter: {
        searchInput: 'User Query Term',
      },
    },
    noMorePhotos: 'No more photos to load!',
    uploadStatusCard: {
      successMessage: 'Upload Complete!',
      pendingCount: {
        one: 'One item waiting to upload',
        other: '%{count} items waiting to upload',
      },
      processingCount: {
        one: 'Processing one item',
        other: 'Processing %{count} items',
      },
    },
    viewMenu: {
      assetSizeToggle: {
        title: 'Photo Size',
        labelSmall: 'Small',
        labelMedium: 'Medium',
        labelLarge: 'Large',
      },
      assetTypeToggle: {
        title: 'Type',
        labelAll: 'All',
        labelPhotos: 'Photos Only',
        labelVideos: 'Videos Only',
      },
      showTagsToggle: {
        title: 'Show Tags',
      },
      sortOrderToggle: {
        title: 'Order',
        labelNewest: 'Newest First',
        labelOldest: 'Oldest First',
      },
      tagsOperatorToggle: {
        title: 'Filter Tags By',
        andOperator: 'And',
        orOperator: 'Or',
      },
      buttonText: 'View',
    },
  },
  assets: {
    selectedAssetBar: {
      tag: 'Tag',
      duplicate: 'Duplicate',
      setCoverPhoto: 'Set as Cover Photo',
      createBeforeAfter: 'Create Before & After',
      beforeAfterTooltip: 'Select two photos to get started.',
      actions: 'Actions',
      print: 'Print',
      project: 'Project',
      hideInProjectTimeline: 'Hide in Project Timeline',
      unhideInProjectTimeline: 'Unhide in Project Timeline',
      move: 'Move',
      integrations: 'Integrations',
      reports: 'Reports',
      createNewReport: 'Create New Report',
      createFromTemplate: 'Create Report from Template',
      addToReport: 'Add to Existing Report',
      gallery: 'Gallery',
      mixedMediaWarning:
        'Heads up! Any videos selected will not be added to your report.',
    },
    description: {
      saved: 'Saved',
      header: 'Description',
      addDescription: 'Add a description…',
      descriptionHint:
        'Info added here can be used in other places in the future, like reports!',
    },
    galleryContainer: {
      editPhoto: 'Edit Photo',
      toggleFullscreen: 'Toggle Fullscreen',
      zoomOut: 'Zoom Out',
      zoomIn: 'Zoom In',
    },
    reportModal: {
      selected: {
        one: 'Review %{count} Photo',
        other: 'Review %{count} Photos',
      },
      clearSelected: 'Clear Selection',
    },
  },
  colors: {
    red: 'Red',
    orange: 'Orange',
    yellow: 'Yellow',
    green: 'Green',
    blue: 'Blue',
    purple: 'Purple',
    navy: 'Navy',
    lightgray: 'Light Gray',
    darkgray: 'Dark Gray',
  },
  common: {
    edit: 'Edit',
    view: 'View',
    add: 'Add',
    pages: 'Pages',
    documents: 'Documents',
    reports: 'Reports',
    taskList: 'Checklists',
    payments: 'Payments',
    save: 'Save',
    lastUpdated: 'Last updated',
    lastUsed: 'Last used',
    close: 'Close',
    new: 'New',
    scroll: {
      left: 'Scroll to the left',
      right: 'Scroll to the right',
    },
    cancel: 'Cancel',
    create: 'Create',
    update: 'Update',
    companyName: 'Company Name',
    password: 'Password',
    account: 'Account',
    all: 'All',
    and: 'and',
    assign: 'Assign',
    customers: 'Customers',
    gotIt: 'Got It!',
    checklists: 'Checklists',
    clear: 'Clear',
    collaborations: 'Collaborations',
    comingSoon: 'Coming Soon',
    comments: 'Comments',
    contacts: 'Contacts',
    copied: 'Copied',
    copyLink: 'Copy Link',
    error: 'Well, Shoot.',
    remove: 'Remove',
    delete: 'Delete',
    moveToTrash: 'Move to Trash',
    deleteProject: 'Delete Project',
    confirmDeleteMessage: 'Are you sure you want to delete this item?',
    confirmDelete: 'Yes, delete',
    cancelDelete: "No, don't delete",
    email: 'Email',
    emailAddress: 'Email Address',
    workEmail: 'Work Email',
    phoneNumber: 'Phone Number',
    other: 'Other',
    toggleOn: 'Yes',
    toggleOff: 'No',
    photos: 'Photos',
    download: 'Download',
    downloadOriginal: 'Download Original',
    downloadModified: 'Download Modified',
    downloadPDF: 'Download PDF',
    updating: 'Updating…',
    loadMore: 'Load More',
    loading: 'Loading…',
    share: 'Share',
    done: 'Done',
    confirm: 'Confirm',
    menu: 'Menu',
    search: 'Search',
    title: 'Title',
    type: 'Type',
    tags: 'Tags',
    label: 'Label',
    labels: 'Labels',
    users: 'Users',
    groups: 'Groups',
    people: 'People',
    projects: 'Projects',
    ariaLabelWaveEmoji: 'Waving hand emoji',
    thisCompany: 'this company',
    continue: 'Continue',
    learnMore: 'Learn More',
    tryAgain: 'Try Again',
    active: 'Active',
    uhOh: 'Uh Oh!',
    copy: 'Copy',
    copySuccess: 'Link Copied to Clipboard',
    retry: 'Retry',
    hidePhotos: 'Hide Photos',
    hidePhoto: 'Hide Photo',
    numberSelected: {
      one: '%{count} Selected',
      other: '%{count} Selected',
    },
    export: 'Export',
    exportPdf: 'Export PDF',
    exportToPdf: 'Export to PDF',
    recentlyUsed: 'Recently Used',
    back: 'Back',
    edited: 'Edited',
    untitled: 'Untitled',
    submit: 'Submit',
    templates: 'Templates',
    changesSaved: 'Changes Saved',
    upgrade: 'Upgrade',
    unexpectedError: 'An unexpected error occurred, please try again later.',
    portfolio: 'Portfolio',
  },
  photoProjectTasks: {
    getErrorMessage: 'Something went wrong while getting your tasks.',
    tooltipOnboardingOne:
      'See something that needs to be done? Create a project task directly from this photo!',
    tooltipOnboardingTwo:
      'Tasks created from photos are automatically added to the Tasks section on the project screen.',
    videoNotSupportedNotice: "Creating tasks from videos isn't supported yet.",
  },
  projectTasks: {
    createTaskErrorMsg: 'There was an error while creating a task',
    deleteTaskErrorMsg: 'There was an error while deleting a task',
    deleteTaskSuccessMsg: 'Task deleted',
    editTaskErrorMsg: 'There was an error while editing a task',
    updateTaskStatusErrorMsg:
      "There was an error while updating the task's status",
    updateTaskPositionErrorMsg: 'There was an error while rearranging the task',
    getErrorMessage: 'Something went wrong while getting your tasks.',
    emptyStateMessage: 'Add to-dos to help keep your project on track.',
    showCompletedButton: 'Show Completed',
    newTaskButton: 'New Task',
    hideCompletedButton: 'Hide Completed',
    updateTaskErrorMsg: 'There was an error while updating the task',
  },
  billing: {
    itunesPlan: 'iTunes',
    basicPlan: 'Basic',
    proPlan: 'Pro',
    premiumPlan: 'Premium',
    elitePlan: 'Elite',
    billingCycle: {
      continueButton: 'Continue',
    },
    planDetails: {
      perUser: 'per user',
      perMonth: 'per month',
    },
    useSubscriptionPlanUpdate: {
      planUpdated: 'Plan updated!',
      planUpdateFailed: 'There was a problem updating plan',
    },
    creditCardForm: {
      addressLine_1: 'Address Line 1',
      addressLine_2: 'Address Line 2',
      city: 'City',
      state: 'State',
      country: 'Country',
      postalCode: 'Postal Code',
      processing: 'Processing…',
      saveCard: 'Save Card and Finish',
    },
    discount: {
      discountDetailsPart1: '%{discountAmount}',
      discountDetailsPart2: '%{end}',
    },
    index: {
      pageTitle: 'Billing Info',
      subscriptionType: 'Subscription Type',
      activeUsers: 'Active Users',
    },
    invoice: {
      invoice: 'INVOICE: %{invoiceNumber}',
      print: 'Print',
      greeting: 'Hi, %{companyName}',
      'paymentProcessed!': 'Your payment has been processed!',
      coupon: 'Coupon: %{discountValue} off',
      total: 'TOTAL',
      accountBalance: 'ACCOUNT BALANCE',
      paid: 'PAID',
      thanksTitle: 'Thanks for using CompanyCam!',
      thanksCopy:
        'The total on your invoice is based on the number of active users in your account. Your subscription is prepaid for the upcoming month.',
      questions: 'Questions? Email hello@companycam.com',
    },
    invoiceLine: {
      subscriptionMessage: '%{quantity}x Subscription %{planName}',
    },
    invoiceList: {
      servicePeriod: 'Service Period',
      chargedAmount: 'Amount',
      dateCharged: 'Date Charged',
      invoice: 'Invoice',
    },
    invoiceListItem: {
      view: 'View',
    },
    paymentDetails: {
      cardEnding: '%{cardBrand} ending %{cardLast4}',
      expires: 'expires %{expiresMonth}/%{expiresYear}',
      updateCard: 'Update Card',
      addCard: 'Add Card',
    },
    subscriptionDetails: {
      viewPaymentHistory: 'View Payment History',
      savingsAmount: 'Switch to %{savingsAmount}',
      userCount: '%{userCount}',
      emptySeats: 'You have %{emptySeatsCount} empty seats',
    },
    subscriptionExpired: {
      signingOut: 'Signing Out...',
      accountNotActiveTitle: 'Your CompanyCam account is not active.',
      accountNotActiveSubTitle:
        'Contact your company admin to reactivate your account.',
      addPaymentInfo: 'Add Payment Information',
      chooseBillingCycle:
        "Choose if you'd like to be billed monthly or annually and then continue.",
      signOutButton: 'Sign Out',
    },
    v2: {
      currentPlan: {
        activeUsers: 'Users',
        manageUsers: 'Manage Users',
        emptySeatsPlural: {
          one: '%{count} open seat',
          other: '%{count} open seats',
        },
        billingPeriod: 'Billing Period',
        perUserPerYear: '%{price}/user/year',
        perUserPerMonth: '%{price}/user/month',
        switchAndSave: 'Switch to %{title} (save $%{amount})',
        switchWithoutSave: 'Switch to %{title}',
        switch: 'Switch to %{title}',
        title: 'Current Plan',
        trial: '%{title} (Trial)',
        trialEndsIn: 'Current trial ends ',
        comparePlansPage: 'See Available Plans',
        cancelPlan: 'Cancel Plan',
        planCancelsOn: 'Set to cancel on %{cancellationDate}',
      },
      signaturesPromoModal: {
        notNow: 'Not Now',
        getSignatures: 'Upgrade',
        signatures: 'Signatures',
        featureOne: 'Request, collect, and save digital signatures',
        featureTwo: 'Get flat-rate access for your whole team',
        featureThree: 'Powered by Dropbox Sign',
      },
      addOn: {
        manageAddOn: 'Manage Add-On',
        renewAddOn: 'Renew Add-On',
        addOn: 'Add-On',
        pricePerMonth: '%{price}/month',
        pricePerYear: '%{price}/year',
        nextPaymentOn: 'Next payment on %{date}',
        cancelled: 'Cancelled',
        tooltip:
          'Add-ons are features available to Pro and Premium account holders to purchase at a flat rate (not per user)',
        title: 'Add-On',
        checkoutModal: {
          signaturesTitle: 'Get Signatures in CompanyCam',
          learnMore: 'Learn More',
          payNow: 'Pay Now',
          processingPayment: 'Processing Payment',
          paymentInformation: 'Payment Information',
          totalDue: 'Total Due',
          totalDescription: "You'll be charged a prorated amount today",
          paymentError: {
            message:
              'We weren’t able to process your payment. Please try again.',
          },
        },
        perYear: '%{price}/year',
        perMonth: '%{price}/month',
        signaturesPrice: '$49/month',
        startCheckoutSessionError:
          'There was a problem preparing your checkout. Please try again.',
        promoModal: {
          upgrade: 'Upgrade',
          upgradePrompt: 'to a paid subscription to purchase this add-on.',
          adminOnlyPrompt: 'Only account admins can enable this feature.',
        },
        signaturesPurchasedModal: {
          watchTutorial: 'See the Tutorial',
          title: "Added! Let's get to signing.",
          subtitle:
            'You can now get documents signed in CompanyCam. Click the three dots on any PDF document and choose Request Signatures to get started. You can manage this subscription anytime on your billing page.',
        },
      },
      upgrade: {
        monthly: '%{planTitle} Monthly',
        annual: '%{planTitle} Yearly',
        annualSavings: '(save $%{savings} annually)',
        total: 'Total Due',
      },
      userCount: {
        one: '%{count} user',
        other: '%{count} users',
      },
      annualSavings: '($%{annualSavings} annual savings)',
      comparePlans: {
        title: 'Compare Plans',
        loadingPlans: 'Loading plans...',
      },
      index: {
        title: 'Billing',
        error: 'There was an error loading billing information.',
        tryAgain: 'Try Again',
      },
      nextPayment: {
        title: 'Next Payment',
        planExpires: 'Plan Expires',
        on: 'on %{date}',
        viewPaymentHistory: 'View Payment History',
      },
      paymentMethod: {
        title: 'Payment Method',
        cardUpdated: 'Card Updated!',
        addPaymentMethod: 'Add Payment Method',
        paymentDetails: 'Payment Details',
        upgradePlan: 'Upgrade Plan',
      },
      trial: {
        subtext: "You won't be charged until after your free trial ends",
      },
      currentPaymentInfo: {
        updatePaymentMethod: 'Update Payment Method',
        linkPaymentMethod: 'Connected via Stripe Link',
        accountInfo: '%{institution} •••• %{lastFour}',
      },
      ach: {
        unableLinkBankAccount: 'Unable to link bank account',
      },
      plaid: {
        linkBankAccount: 'Link Bank Account',
        updateBankAccount: 'Update Bank Account',
      },
      creditCardInfo: {
        addCard: 'Add Credit Card',
        subtitle: '%{cardBrand} ending %{lastFour}',
        message: 'expires %{expiry}',
        updateCard: 'Update Card',
      },
      itunesInfo: {
        subtitle: 'iTunes',
        message: 'You are currently paying with a subscription through Apple.',
        manageSub: 'Manage iTunes Subscription',
      },
      manualInfo: {
        subtitle: 'Custom Plan',
        message:
          "You're currently on a custom pricing plan. Email support@companycam.com to request any changes to your current plan.",
        emailSupport: 'Email Support',
      },
      updatePaymentModal: {
        addPayment: 'Add Payment Method',
        updatePayment: 'Update Payment Method',
        paymentInfo: 'Payment Information',
        goodChoice: 'Good Choice!',
        chooseCycleDescription:
          'Do you want to be billed monthly or annually? Select one and choose your payment preference.',
        pricingDisclaimer:
          'Note: Pricing may not reflect any applied discounts.',
        title: 'Update Payment',
      },
      payWithCreditCard: 'Pay with Credit Card',
      updateCreditCard: 'Update Credit Card',
      addPaymentInfo: 'Add Payment Info',
      updatePaymentInfo: 'Update Payment Info',
      confirmYourPlan: 'Confirm Your Plan',
      premiumTrial: {
        premiumTrialDaysLeft: 'Your trial ends %{days}',
        premiumCardTitle: 'Try Premium Free for 14 days',
        premiumCardTrialFeaturesOne:
          'Get Project, Report, Document, and Checklist Templates',
        premiumCardTrialFeaturesTwo:
          'Try assigned checklists to keep your team on track',
        premiumCardTrialFeaturesThree:
          'Impress customers with video walkthroughs and more',
        premiumCardTrialButtonLabel: 'Try Premium for Free',
        upgrade: 'Upgrade',
        premiumTrialModalTitle: 'Your Free Premium Trial Timeline',
        premiumTrialTimelineHeadingOne: 'Today',
        premiumTrialTimelineSubHeadingOne:
          'Get full access to Premium features like Project and Checklist Templates.',
        premiumTrialTimelineHeadingTwo: 'In 12 Days',
        premiumTrialTimelineSubHeadingTwo:
          'You`ll receive an email about your trial ending and can decide if you want to stick with Premium or go back to Pro.',
        premiumTrialTimelineHeadingThree: 'In 14 Days',
        premiumTrialTimelineSubHeadingThree:
          'You`ll be charged a pro-rated amount and get to keep all your favorite features!',
        premiumTrialModalCancelHeading:
          'Decide you want to return to your Pro plan?',
        premiumTrialModalCancelCopy:
          'No problem. At any point during your trial, visit your billing page on the web and click the button below your subscription information that says “Return to Pro”',
        due: 'Due',
        trialLoading:
          'Activating Premium features for your whole company. This may take a moment.',
        startTrial: 'Start My Premium Trial',
        nevermind: 'Nevermind',
        trialStartError: 'There was a problem starting your trial. Try again.',
        premiumTrialStartActivated: 'Premium trial activated',
        cancelPremiumTrialBadge: 'Active',
        cancelPremiumTrialHeader: 'Premium Trial',
        cancelPremiumTrialCardText:
          'You are currently on a Premium Trial. When the trial expires you will be charged for Premium unless you return to Pro by clicking below.',
        cancelPremiumTrialCardButtonLabel: 'Return to Pro',
        cancelPremiumTrialModalTitle: 'If you cancel, you’ll lose access to...',
        cancelPremiumFeatureOne: 'Checklist Templates',
        cancelPremiumFeatureTwo: 'High Quality Capture',
        cancelPremiumFeatureThree: '4K Videos',
        cancelPremiumFeatureFour: 'Project Templates',
        cancelPremiumFeatureFive: 'Report Templates',
        cancelPremiumFeatureSix: 'Logo Stickers and More!',
        cancelPremiumTrialError:
          'There was a problem cancelling your trial. Please reach out to support.',
        declineCancelPremiumTrialLabel: "No, Don't Cancel",
        cancelPremiumTrialLabel: 'Yes, Cancel My Upgrade',
        whatIsPremium: "What's Included with Premium?",
        canceledPremiumTrialMessage: 'Your Premium trial has ended',
      },
      premiumSample: {
        disallowedPremiumSampleActions:
          'This action is not available in your current trial. To cancel your trial, click the "Return to Pro" button below.',
      },
    },
    choosePlans: {
      pageTitle: 'Choose Plan',
      header: 'Choose a plan for your 14-day free trial',
      monthlyOption: 'Pay Once a Month',
      annualOption: 'Pay Once a Year',
      skipButton: 'Skip this step',
      trialInfoHeader: 'We’ll send a reminder 2 days before your trial ends.',
      trialInfoSubHeader:
        'Change plans or cancel anytime by visiting your billing page.',
      startTrial:
        'Start your free 14-day trial and get instant access to the platform.',
      today: 'Today',
      reminderInfo:
        'We’ll email you a reminder that your trial is ending soon.',
      subscriptionInfo:
        'Your subscription will start unless you’ve canceled during the trial.',
      trialHeader: 'Choose a plan for your 14-day free trial',
      trialSubheader:
        "You'll get a reminder before your trial ends. You can change plans or cancel any time on your billing page.",
    },
    comparePlans: {
      header: 'Compare Plans',
      payYearly: 'Pay Yearly & Save',
      payMonthly: 'Pay Monthly',
      monthlyOption: 'Pay Once a Month',
      annualOption: 'Pay Once a Year',
      returnToBilling: 'Return to Billing',
      returnToProjects: 'Projects',
      billableUser: '1 Billable User',
      billableUsers: '%{userCount} Billable Users',
      questions: 'Questions?',
      chatWithSupport: 'Chat with Support',
      reachOut:
        'Reach out to our support team and they can connect you with your sales representative.',
      contact: 'Contact Support',
    },
    card: {
      currentPlan: 'Current Plan',
      choosePlan: 'Choose Plan',
      keyFeatures: 'Key Features:',
      premiumFeaturesHeader: 'Everything in Pro, and:',
      eliteFeaturesHeader: 'Everything in Premium, and:',
      popularTag: 'MOST POPULAR',
      currentTrial: 'CURRENT TRIAL',
      userIncludes: 'Includes 3 Users',
      userPricing: 'Additional Users for $%{userPrice}/month',
      yearlyTag: 'Billed Yearly',
      monthlyTag: 'Billed Monthly',
      freeTrialCTA: 'Start Free Trial',
      perMonth: '/month',
      videoWalkthrough: 'Video Walkthrough Mode',
      paymentProcessing: 'Payment Processing',
      documentSigning: 'Document Signing',
      projectTemplates: 'Project Templates',
      reportTemplates: 'Report Templates',
      checklistTemplates: 'Checklist Templates',
      videoCapture: '10-minute Video Capture',
      logoStickers: 'Logo Stickers',
      dedicatedSuccessManager: 'Dedicated Success Manager',
      companyDashboard: 'Company Dashboard',
      aiReporting: 'AI Reporting',
      photoWatermark: 'Photo Watermark',
      collaboratorAccess: 'Collaborator Access',
      unlimitedStorage: 'Unlimited Projects & Storage',
      photoReports: 'PDF Photo Reports',
      beforeAfters: 'Before & Afters',
      checklists: 'Checklists',
      integrations: 'Software Integrations',
      unlimitedPhotoStorage: 'Unlimited Photo Storage',
      fiveMinuteVideoCapture: '5-minute Video Capture',
      unlimitedDocumentStorage: 'Unlimited Document Storage',
      unlimitedIntegrations: 'Unlimited Integrations',
      unlimitedProjects: 'Unlimited Projects',
      tenProjects: '10 Projects',
      photoCommentsTags: 'Photo Comments & Tags',
      photoAnnotations: 'Photo Annotations',
      documentScanning: 'Document Scanning',
    },
    stripePaymentModal: {
      timeline: {
        timelineHeaderOne: 'Today: Get Instant Access',
        timelineHeaderTwo: 'Day 12: Trial Reminder',
        timelineHeaderThree: 'Day 14: Subscription Starts',
        timelineSubHeaderOne: 'Unlock our best features. Cancel anytime.',
        timelineSubHeaderTwo:
          'We’ll remind you that your trial is ending soon.',
        timelineSubHeaderThree: 'Your subscription will start on',
        timelineTrialInfoFirst: '14-day free trial, then ',
        timelineTrialInfoMonth: '$%{price} per month',
        timelineTrialInfoYear: '$%{price} per year',
        timelineTrialInfoSubHeader:
          '3 users included in the starting price. Each additional user is $%{monthlyPricePerUser} per month. Plus taxes where applicable.',
      },
      billingError: 'An unexpected error occurred, please try again later.',
      paymentMethodFailed: 'Failed to load existing payment method information',
      annualPlan: '%{planTitle} Annual Plan',
      monthlyPlan: '%{planTitle} Monthly Plan',
      timesThreeUsers: 'x 3 users',
      due: 'Due',
      dueToday: 'Due Today',
      afterTrialDisclaimer:
        'Amount charged after trial is subject to change based on the number of users invited. Plus taxes where applicable.',
      whenChargedInfo: 'You won’t be charged until after your free trial.',
      reminderInfo: 'We’ll remind you 2 days before it ends.',
      tooltipAccessibilityLabel: 'User pricing information',
      startTrial: 'Start Free %{planTitle} Trial',
      addPaymentMethod: 'Add Payment Method',
      continue: 'Continue',
      paymentSaved: 'Payment information saved!',
      verifying: 'Verifying',
      confirm: 'Confirm',
      paymentSource: '%{paymentSourceBrand} ending in %{paymentSourceLast4}',
    },
    subscriptionCycle: {
      monthlyOption: 'Pay Once a Month',
      annualOption: 'Pay Once a Year',
    },
    planSummary: {
      basicFeatures: 'Basic Features',
    },
    trialRemaining: 'Trial ends %{readableTimeRemaining}',
  },
  checklistFeed: {
    checklistFilter: {
      all: 'All',
      complete: 'Completed',
      incomplete: 'Uncompleted',
    },
    checklistRow: {
      completedProgress: '%{tasksCompleted}/%{totalTasks} completed',
    },
    index: {
      emptyStateHeader: 'No Checklists Found',
      emptyStateDescription:
        'Checklists created within a project will show up here.',
      completeEmptyState: 'No (Complete) Checklists to Show',
      incompleteEmptyState: 'No (Incomplete) Checklists to Show',
      title: 'Checklist Feed',
      featureName: 'Checklists',
      searchPlaceholder: 'Search for a checklist',
      errorState:
        'Your checklists are safe, but because of a technical issue on our end, we couldn’t load them. Try refreshing the page, but if the issue continues, contact Support.',
    },
  },
  projects: {
    taskList: {
      completedProgress: '%{tasksCompleted}/%{totalTasks} completed',
      assign: {
        assignUsersButton: 'Assign',
        assignUserEmptyStateMessage:
          'Assigning checklists can increase completion rate by 17%.',
        unassignButton: 'Unassign',
        createTaskListAssignment: {
          successMessage: 'User successfully assigned.',
          errorMessage:
            'There was an error assigning a user. Please reload the page and try again',
        },
        removeTaskListAssignment: {
          successMessage: 'User successfully unassigned.',
          errorMessage:
            'There was an error unassigning a user. Please reload the page and try again',
        },
        restrictedUserConfirmationModal: {
          title: 'Assign Restricted User?',
          confirmButton: 'Yes, Assign',
          message:
            'Assigning this user to a checklist gives them access to the project. They will be able to view Photos, add Photos, join Conversations, and access Reports and Checklists.',
          subtitle: '%{name} is a Restricted user',
        },
      },
      changesSaved: {
        success: 'Changes Saved',
        lastUpdated: 'Last Updated',
        loading: 'Saving',
      },
      templates: {
        createToDo: 'Create Checklist',
        emptyMessage:
          'Create a checklist and save it as a template to find it here.',
        success: {
          use: 'The checklist was succesfully created',
          delete: 'The template was succesfully deleted',
        },
        all: 'All Templates',
        recent: 'Recently Used Templates',
        defaultFilter: {
          label: 'All Templates',
        },
        deleteConfirm: 'Are you sure you want to delete this template?',
        templateDetailsInput: {
          titleLabel: 'Template Title',
          descriptionLabel: 'Template Description',
        },
        templateHeader: {
          reorderTasks: 'Reorder Fields',
          taskTemplatesCount: '%{count} Fields',
          templateTitlePlaceholder: 'Enter Template Title',
          templateDescriptionPlaceholder: 'Enter Template Description',
          templateDescriptionError:
            'Something went wrong while updating the template details.',
          templateDisclaimer:
            'You’re currently editing a template. Any changes you make will not affect previously created lists.',
        },
        delete: 'Delete Template',
        edit: 'Edit Template',
        useTemplate: 'Use Template',
        backToTemplates: 'Back to Templates',
        empty: 'No Templates Found',
        list: {
          heading: 'Template Library',
        },
      },
      removeReferenceAsset: 'Remove Reference Photo',
      reorderTasks: {
        error: 'Something went wrong while reordering your fields.',
      },
      sortableSection: 'Drag and Drop to reorder sections.',
      sortableTasks: {
        taskMoveError: 'There was an error moving field to this section',
        newTask: 'Add Field',
      },
      addTaskButton: {
        newTask: 'Add Field',
      },
      taskInputs: {
        addNotes: 'Add Notes/Instructions',
        untitledTask: 'Untitled Field',
      },
      addReferencePhoto: 'Add Reference Images',
      taskDeleteModal: {
        deleteTaskTitle: 'Delete Field',
        deleteTaskBody: 'Are you sure you want to delete this field?',
        deleteError: 'An error occured deleting this field.',
      },
      viewReferenceImages: 'View Reference Images',
      templateBodyContent: {
        referencePhotoHeading: 'Reference Photos',
        newSection: 'New Section',
        createSectionError: 'An error occurred creating this section.',
      },
      taskAssetFooter: {
        uploadPhotos: 'Upload Photos',
      },
      removePhotoFromTask: 'Remove From Field',
      taskImageDeleteModal: {
        taskImageDeleteTitle: 'Remove Photo From Field',
        taskImageDeleteBody:
          'Are you sure you want to remove this photo from the field?\n  This will not delete the photo from the project entirely.',
        taskImageDeleteConfirm: 'Yes, Remove',
        taskImageDeleteCancel: "No, Don't Remove",
        taskImageDeleteError: 'There was an error removing photo',
      },
      taskListHeader: {
        backToTaskLists: 'Back to Project',
        tasksCompleted: 'fields completed',
        editTaskList: 'Rename List',
        exportToPdf: 'Export to PDF',
        reorderTasks: 'Reorder Fields',
        saveAsTemplate: 'Save List as Template',
        deleteTaskList: 'Delete List',
        tooltipMsg: 'Save as a template to quickly build future checklists',
        tooltipUpgradeMsg:
          'Upgrade to save as a template to quickly build future checklists',
      },
      taskListSection: {
        skipSectionLabel: 'Marked N/A',
        skipSectionTooltip: 'Marked as Not Applicable by %{user}, %{date}',
        templateSectionTooltip:
          'Toggle off to allow users on mobile to mark this section as Not Applicable.',
        toggleSkipSectionLabel: 'Section Required',
        newSection: 'New Section',
        reorderSections: 'Reorder Sections',
        applyTagsToImagesWithinSection: 'Tag Section Photos',
        deleteSection: 'Delete Section',
        deleteSectionError: 'An error occurred deleting this section.',
        updateSectionError: 'An error occurred updating this section.',
        sectionSkipError: 'An error occurred toggling Section Required',
        sectionDeleteError: 'An error occurred updating this section',
        sectionCreateError: 'There was an error creating this section',
        sectionEditError: 'There was an error editing this section',
      },
      taskListSectionDeleteModal: {
        sectionDeleteTitle: 'Delete Section',
        sectionDeleteBody:
          'Are you sure you want to delete this section? <strong>This will also delete all the fields inside this section.</strong>',
      },
      task: {
        taskPhotoRequired: 'Photos Required',
        taskDelete: 'Delete Field',
        taskUpdateError: 'Error updating field',
        taskCreateError: 'There was an error creating this field',
        taskStatusUpdateError: 'Error updating field status',
        taskCompleted: 'Field Completed',
        taskCompleteUndo: 'Undo',
      },
      addPhotosToTask: 'Add Photos',
      create: 'Create Checklist',
      addTitle: 'New Checklist',
      useExistingTemplate: 'Use Existing Template',
      taskPhotoUploadModal: {
        photosFinished:
          'Your photos have finished uploading and are now processing. They will appear shortly in your project and field. \n    To continue adding photos, click here or drag & drop additional photos',
        addPhotoError: 'There was an error adding the photo to the field',
        delete: {
          error: 'Something went wrong while deleting this reference image.',
        },
        uploadPhoto: 'Upload Photos',
        addPhotosSuccess: 'Photos added',
      },
      customerHappiness: 'Customer Happiness',
      initialInspection: 'Initial Inspection',
      maintenanceOrder: 'Maintenance Order',
      materials: 'Materials',
      qA: 'QA',
      safety: 'Safety',
      workOrder: 'Work Order',
      other: 'Other',
      siteSurvey: 'Site Survey',
      reorderSectionsContainer: {
        heading: 'Something went wrong when reordering your sections.',
      },
      saveTemplateError: 'There was an error saving the template',
      saveTemplateSuccess: 'Template Saved!',
      noTaskLists: "This project doesn't have any checklists yet.",
      exportPdf: {
        errorMessage: 'Error',
        errorDescription: 'There was an error retrieving the checklist PDF',
        completeMessage:
          "We've received your request and are currently building your checklist PDF.",
        completeDescription:
          "We'll send you a notification when it's ready to go. This shouldn't take more than a minute or two.",
        savedDescription:
          'A copy has also been saved to your project documents.',
        description:
          'This PDF export will be a snapshot of this list in its current state.',
        includePhotosToggle: 'Include Photos',
        includePhotoDescriptionToggle: 'Include Photo Capture Details',
        saveToProjectDocumentsToggle: 'Save to Project Documents',
        photosPerRowToggle: 'Photos per Row',
        exportBtn: 'Export',
        includeSkippedSectionsToggle: 'Include Sections Marked N/A in Export',
        successMessage: 'Success!',
        successDescription: 'Your PDF is ready to view.',
        viewPdf: 'View PDF',
      },
      referenceModal: {
        addReferencePhoto: 'Upload Reference Images',
        addReferencePhotoDisabled:
          'A maximum of 5 reference images are allowed',
        comingSoon: 'Coming Soon',
        confirmDelete: 'Are you sure you want to delete this reference image?',
        maxReferencePhotoUploadCount: 'Maximum uploads: %{count}',
        referencePhotoModalTitle: 'Reference Images',
        referencePhotoModalSubtitle:
          'Reference images help your team know what an ideal photo should look like to properly complete this field.',
        referencePhotoAddProjectPhotos: 'Add Photos From Project',
      },
      referencePhotoUploadModal: {
        success: {
          one: 'Review %{count} reference image',
          other: 'Review %{count} reference images',
        },
        error: 'Something went wrong while uploading %{fileName}.',
      },
      reorderTasksContainer: {
        heading: 'Drag and Drop to reorder fields',
      },
      subTasks: {
        createSubtask: {
          error: 'There was an error creating a question',
        },
        deleteError: 'An error occurred deleting this question.',
        multiSelect: {
          comingSoon: 'Coming Soon',
          answerChoiceAlert:
            'Editing options will clear any existing answer selections.',
        },
      },
      taskListTitleColumnHeader: 'Checklist',
      taskListAssigneesColumnHeader: 'Assignees',
      taskListDateColumnHeader: 'Latest Activity',
      taskListProgressColumnHeader: 'Progress',
      taskAssetsModal: {
        taskAddProjectPhotos: 'Add Photos From Project',
      },
      deleteTitle: 'Delete List',
      deleteBody:
        'Are you sure you want to delete this checklist? You cannot undo this action.',
      deleteError: 'There was an error deleting the checklist.',
      addTaskListModalTitle: 'New Checklist',
      editTitle: 'Edit Checklist',
      taskListFormLabel: 'Checklist Title',
      taskListNameInputPlaceholder: 'Untitled Checklist',
      taskListCategoriesInstructions:
        "Tell us a little more about how you'll use this checklist (optional)",
      addTaskListButtonText: 'Create Checklist',
      addNewTaskListError: 'There was an error adding this checklist',
      editTaskListError: 'There was an error editing this checklist',
      taskListFormModal: {
        templateCreated: 'Template Created',
        templateCreatedError: 'There was an error creating the template',
        templateCreate: 'Create New Template',
        templateCreateFromToDo: 'Save List as Template',
        templateDescriptionPlaceholder: 'Add a template description',
        templateDescriptionLabel: 'Template Description',
        buttonLoadingLabel: 'Creating Template...',
      },
      taskListAssigneesTooltip: 'People assigned to the checklist',
      taskListViewOthersPhotosPermission:
        'You do not have permission to access checklists. Please contact your administrator.',
      template: {
        beingEditedStatus: 'Update in Progress',
      },
      templateHeader: {
        breadcrumb: 'Back to Project',
      },
    },
    users: {
      add: {
        noGroupsFound: 'No groups found.',
        submit: 'Add to Project',
        noUsersFound: 'No users found.',
        inviteNew: 'Invite a new user',
        errorMessage:
          'There was an error adding users to the project: %{errorMessage}',
      },
      selectOption: {
        groupNameFormat: '%{groupName} (group)',
      },
      cta: 'Assign Users',
      title: 'Project Users',
      collabTooltip:
        'Check out our Collaboration feature to invite a subcontractor or service partner to add photos on this project',
      unassign: {
        success: 'User unassigned!',
        undo: 'Undo',
      },
      contributors: 'Contributors',
      deactivated: 'Deactivated',
      unassigned: 'Unassigned',
      errorMessage: "There was an error loading the project's users.",
      tryAgain: 'Try Again',
      listItem: {
        introPara:
          'Users who have contributed and/or been assigned to %{projectName}',
        lastContributedAt: 'Last activity: %{lastContributedAt}',
      },
      anotherCompany: 'Working with another company?',
      collabCta: 'Invite them to collaborate',
      loadMore: 'Load more',
      assigned: 'Assigned',
    },
    trialBanner: {
      timeRemainingEliteSingular:
        'There is 1 day remaining in your Elite trial.',
      timeRemainingElitePlural:
        'There are %{trialDaysRemaining} remaining in your Elite trial.',
      timeRemainingPremiumSingular:
        'There is 1 day remaining in your Premium trial.',
      timeRemainingPremiumPlural:
        'There are %{trialDaysRemaining} remaining in your Premium trial.',
      timeRemainingProSingular: 'There is 1 day remaining in your Pro trial.',
      timeRemainingProPlural:
        'There are %{trialDaysRemaining} remaining in your Pro trial.',
      seePlans: 'See Plans',
      addPaymentMethod: 'Add Payment Method',
    },
    onboardCards: {
      qr: 'Scan this QR code to <strong>get the app and start taking photos.</strong>',
      qrAccessibilityLabel: 'Scan this QR code to get the app',
      invite:
        '<strong>Invite your team</strong> to keep every photo in one place.',
      inviteAccessibilityLabel: 'Invite your team',
      connect:
        '<strong>Connect an integration</strong> to simplify your workflow.',
      connectAccessibilityLabel: 'Connect an integration',
    },
    onboards: {
      demo: '<strong>Watch a demo</strong> to get an overview of all the features.',
      demoAccessibilityLabel: 'Watch a demo',
    },
    pages: {
      exportPdf: {
        includeCoverPage: 'Include Cover Page',
      },
    },
    activeFilters: {
      clearAllAriaLabel: 'Clear All',
    },
    collaboration: {
      collaboratorCardsList: {
        errorCta: 'Reload this page',
        emptyCta: 'Invite a company to collaborate',
        errorHead: 'Shoot. We had trouble loading your collaborators.',
        errorSubhead:
          'Please check your internet connection, and try reloading this page.',
        activeInviteHead:
          'No collaboration invitations have been accepted yet.',
        activeInviteSubhead:
          "When your invitations are accepted, you'll receive a notification and see the collaborator listed on this page.",
        emptyHead:
          'Working with a subcontractor or service partner on this project? %{strong}',
        emptySubhead:
          'Collaborations are unlimited with your current plan. You decide what each company can see and share, and you can stop the collaboration at any time.',
        noCollaboratorsNoPerms: 'This project has no collaborators.',
      },
      privilegeToggle: {
        todosToolTip:
          'Add Photos, View Photos, and Comments must be on for collaborators to access checklists.',
        addPhotoPermissionLabel: 'Add photos permission',
        viewPhotoPermissionLabel: 'View photos permission',
        commentPermissionLabel: 'Comment permission',
      },
      collaborationPrivilegeToggles: {
        addContent: {
          header: 'Add photos',
          description: 'Collaborator can add photos',
        },
        viewContent: {
          header: 'View photos',
          description:
            'Collaborator can view and share all photos in the project',
        },
        comment: {
          header: 'Comment',
          description: 'Collaborator can view and make comments in the project',
        },
      },
      table: {
        errorCta: 'Reload this page',
        emptyCta: 'Invite a company to collaborate',
        active: 'active',
        ended: 'ended',
        emptyState:
          'This search returned no results. Please try a different search term.',
        altTextNoResultsFound:
          'Illustration of a magnifying glass finding no results',
        nameHeader: 'Name',
        contactInfoHeader: 'Contact Info',
        statusHeader: 'Status',
        errorHead: 'Shoot. We had trouble loading your collaborators.',
        errorSubhead:
          'Please check your internet connection, and try reloading this page.',
        activeInviteHead:
          'No collaboration invitations have been accepted yet.',
        activeInviteSubhead:
          "When your invitations are accepted, you'll receive a notification and see the collaborator listed on this page.",
        emptyHead:
          'Working with a subcontractor or service partner on this project? %{strong}',
        emptySubhead:
          'Collaborations are unlimited with your current plan. You decide what each company can see and share, and you can stop the collaboration at any time.',
        noCollaboratorsNoPerms: 'This project has no collaborators.',
      },
      index: {
        searchInputPlaceholder: 'Find a collaboration...',
        title: 'Collaborators',
        tryAgain: 'Try again',
        viewYourProjects: 'View your projects',
        invitationBtnLabel: 'Invitation to collaborate with other companies',
        noAccess: "Sorry! It looks like you don't have access to this feature.",
        errorMessage: 'Shoot. We had trouble loading your project.',
        projectInactive: 'Uh-oh. This project is inactive.',
        inviteCta: 'Invite a Company to Collaborate',
      },
      menu: {
        endCollaboration: 'End collaboration',
        reInvite: 'Re-invite Company',
      },
      collaboratorCard: {
        addedBy: 'Added by %{creatorName} • %{creationDate}',
      },
      emptyErrorCollaboratorList: {
        altText: 'Illustration showing two workers collaborating',
      },
      endCollaborationConfirmModal: {
        modalTitle: 'Are you sure?',
        modalInstructions: 'If you end the collaboration with',
        modalWarning:
          "they won't be able to see any ongoing updates to this project unless you re-invite them.",
        modalCollaboratorWarning:
          "you won't be able to see any ongoing updates to this project unless they re-invite you.",
        endingMessage: 'Ending Collaboration...',
        confirm: 'Yes, End Collaboration',
        cancel: 'Nevermind',
      },
      invitationLink: {
        creating: 'Creating invite link…',
        noPrivilegesActive:
          'Approve at least one privilege above to create your link.',
      },
      invitationLinkShareButtons: {
        settingPrivs: 'Setting invite privileges...',
        emailSubject:
          'Collaborate with %{projectOwningCompany} on %{projectName}',
        emailLine1:
          "You've been invited to collaborate with %{projectOwningCompany} on the %{projectName} project using CompanyCam.",
        emailLine2:
          'This invite expires in **7 days**. Accept the invitation here: %{linkUrl}',
        emailLine3: 'Happy Collaborating!',
        copyToClipboard: 'Copy to Clipboard',
        sendViaEmail: 'Send via Email',
      },
      invitationLinkText: {
        accessiblityLabel: 'Collaboration invitation link',
        error: 'Uh oh. There was an error loading this link.',
      },
      invitationSharedSuccess: {
        shared: 'shared',
        emailed: 'emailed',
        nextSteps:
          "Once you've %{action} the link, you can close this window. You'll receive a notification from CompanyCam when the invite is accepted.",
        inviteAnother: 'Want to invite another company?',
      },
      createInvitationModal: {
        linkCopiedToClipboard: 'Link Copied to Clipboard',
        linkCopiedToEmail: 'Link Copied to an Email',
        defaultTitle: 'Invite a Company to Collaborate',
        alText: 'Illustration of a high five',
        sunsetCollabNotice:
          'To bring you checklist and document collaboration, we are removing the three permission settings below on February 3, 2023.',
        finePrint:
          'This link expires after 7 days and can only be used to invite one company.',
        permissionNotice:
          'Collaborators will be able to add photos, view and share all photos, and view and make comments in this project.',
      },
    },
    deleteConfirmationModal: {
      modalTitle: 'Delete Project',
      error: 'There was an error deleting the project',
      modalInstructions:
        'Are you sure you want to delete this project? <strong>All photos at this project will be also be deleted.</strong>',
    },
    projectFilterModal: {
      modalTitle: 'Filter Projects',
      startDate: 'Start Date',
      endDate: 'End Date',
      usersAndGroups: 'Users and Groups',
      selectTags: 'Select Label(s)',
      applyFilter: 'Apply Filter',
    },
    forms: {
      edit: {
        pageTitle: 'Editing %{title}',
        nameOrAddressRequired: 'Name or address required',
      },
      form: {
        createNewProject: 'Create New Project',
        editProject: 'Edit Project',
        cancelAriaLabel:
          'All data you have changed on this page will not be saved.',
        projectName: 'Project Name',
        projectAddress1: 'Project Address 1',
        projectAddress2: 'Project Address 2',
        city: 'City',
        state: 'State',
        postalCode: 'Postal Code',
        country: 'Country',
        createProject: 'Create Project',
        saveProject: 'Save Project',
      },
      new: {
        searchPlaceholder: 'Search Google for address or name',
        closeAriaLabel: 'Close, and manually enter project details',
        pageTitle: 'Create Project',
        nameOrAddressRequired: 'Name or address required',
        modalTitle: 'Create Project',
        noAddress: "Don't have an address for your project? No worries!",
        placeaPin: 'Place a Pin',
        drawaGeofence: 'Draw a Geofence',
      },
      projectTemplates: {
        header: 'Project Templates',
        description: 'Preload your projects with checklists and reports.',
        addButton: 'Use Project Template',
        upgrade: 'Upgrade Your Plan',
        nonAdminUpgradeMessage:
          'You don’t have access to Project Templates. Contact your admin to upgrade your plan.',
        defaultTemplateErrorMsg:
          'There was a problem getting the default project template',
      },
    },
    projectTemplatesModal: {
      header: 'Project Templates',
      lastUpdated: 'Updated %{time} ago',
      emptyStateHeader: "You haven't created any Project Templates",
      emptyStateMessage:
        'To create a Project Template, visit Templates on the CompanyCam Web App',
      defaultTemplateBadge: 'Default Template',
    },
    guests: {
      guestForm: {
        guestName: 'Guest Name',
        guestEmail: 'Guest Email',
        guestPhone: 'Guest Phone Number',
        accessExpiration: 'Access Expiration Date',
        message: 'Message',
        addGuest: 'Add Guest',
        saveGuest: 'Save Guest',
        toggleLabel: 'Allow this guest to view all the photos in this project.',
      },
      guestProject: {
        allProjectPhotos: 'All Project Photos',
        justTheirPhotos: 'Just Their Photos',
        accessLevel: 'Access Level',
        accessExpiration: 'Access Expiration Date',
        never: 'Never',
        confirm: 'Are you sure?',
        resendInvite: 'Resend Invite',
      },
      index: {
        modalSubtitle: 'Add Client / Homeowner',
        modalMessage:
          'Guest Access allows homeowners to view and add photos through a simple web link; no app install required. Guest Access accounts only see photos — nothing else.',
        modalTitle: 'Guest Access',
        addCollaborator: 'Add them as a Collaborator',
      },
      guestProjectsList: {
        addGuestUser: 'Add Guest Access',
      },
    },
    projectListItem: {
      noPhotos: 'No photos have been added to this project yet.',
    },
    listItem: {
      lastUpdated: 'Last Updated',
      recentUserLabel: 'Recent User',
      recentUsersLabel: 'Recent Users',
      docs: 'Docs',
    },
    listContainer: {
      projectsTitle: 'Projects',
      pageTitle: 'Project Feed',
      searchPlaceholder: 'Find a project…',
      emptyProjectFeed: 'Create a project for every job.',
      emptyProjectFeedSubTitle:
        'Photos, documents, and communication for each job will live in the project.',
      createProject: 'Create Project',
      myProjectsNoResults: 'Create a project for every job.',
      myProjectsNoResultsSubTitle:
        'You haven’t contributed to any projects yet, but all of your activity will show up in this list.',
      myProjectsNoResultsAlt: 'An image of a map connected to projects',
      allProjectsNoResults: 'Create a project for every job.',
      allProjectsNoResultsSubTitle:
        'Photos, documents, and communication for each job will live in the project.',
      activeProjectsNoResults: 'Create a project for every job.',
      activeProjectsNoResultsSubTitle:
        'Photos, documents, and communication for each job will live in the project.',
      starredProjectsNoResults: 'No starred projects yet',
      starredProjectsNoResultsSubTitle:
        'Easily keep track of the most important projects by starring them.',
      starredProjectsNoResultsAlt:
        'An image of a starred folder with files inside',
      archivedProjectsNoResultsTitle: 'No archived projects yet',
      archivedProjectsNoResults:
        "You haven't archived any projects yet. When you do, they'll show up in this list.",
      archivedProjectsNoResultsAlt: 'An image of a map connected to projects',
      searchNoResults: "Your search didn't match any projects.",
      noFilterResults:
        "Sorry, there aren't any projects that fit the selected filters.",
      loadMoreProjects: 'Load More Projects',
    },
    projectListItemBadgeRow: {
      archivedLabel: 'Archived',
      collabProjectNotice: 'This project is part of a collaboration',
      collabOwnerNotice: 'Your company owns this collaboration',
      collabEndedNotice: 'collaboration ended',
    },
    recentProjectsToolbar: {
      showButton: 'Show',
      refreshProjects: 'Refresh',
      filterProjects: 'Filter',
      allProjectsOption: 'All',
      starredProjectsOption: 'Starred',
      myProjectsOption: 'My Projects',
      archived: 'Archived',
    },
    show: {
      project: {
        archiveBannerMessage: 'Project archived by %{name} on %{date}.',
        archiveBannerMessage2:
          'This project will be hidden from all company feeds, but you can still view it in the',
        archiveBannerMessage3: 'tab on the Project Feed.',
        unarchiveBtn: 'Unarchive',
        archiveMessage:
          'Project archived by %{name} on %{date}. This project will be hidden from the Nearby Projects feed on the mobile app.',
        inactive: 'This project was deleted on %{deletedOn}.',
      },
      downloadProjectPhotosModal: {
        modalLabel: 'Download all photos',
        modalTitleCompleted: 'Your download is ready!',
        downloadPhotos: 'Download Photos',
        modalTitle: 'Download All Photos',
        instructions:
          "Enter your email and we'll notify you when your download is ready. Your link will also appear here when ready if you'd like to wait.",
        modalTitleSubmitted: 'Packaging Photos',
        modalInstructionsSubmitted:
          "We're packaging your photos and will email them to %{email}. Your link will also appear here when ready if you'd like to wait.",
      },
      googleCalendarLink: {
        googleCalendarLinkTitle: 'Send to Google Calendar',
        googleCalendarDetailsMessage:
          'Event created from this CompanyCam project: %{projectLinkURL}',
      },
      collaborationRow: {
        callout: {
          cta: 'Invite your subcontractors and service partners to collaborate, so they can add photos, too!',
          dismiss: 'OK, Got It',
        },
        countedText: 'Collaborators (%{count})',
        defaultText: 'Collaborators',
        ownerScreenReaderContent: '%{companyName} owns this project',
        error: 'Shoot. There was an error loading collaborators.',
        restrictedFeatureModal: {
          title: "Sorry, you don't have permission to access that.",
          content:
            'You do not have the required permissions to view Collaborator information. Please contact your system administrator for assistance.',
          confirm: 'OK',
        },
      },
      projectSidebar: {
        tasks: 'Tasks',
        notepadReadAll: 'Read All Notes',
        notepadTitle: 'Description',
        modalContentLabel: 'Edit Project Description',
        conversationTitle: 'Project Conversation',
        sidebarOnboardingMessage:
          'You can now collapse the sidebar to give yourself more room to browse content on the project screen. Click the arrow icon to toggle it open and closed.',
        hideSidebarLabel: 'Hide Sidebar',
        showSidebarLabel: 'Show Sidebar',
      },
      membersRow: {
        countedText: 'Project Users (%{count})',
        defaultText: 'Project Users',
        error: 'Shoot. There was an error loading users.',
      },
      integrationActions: {
        requestSentMsg: 'Request Sent!',
      },
      manageActions: {
        project: 'Project',
        editProject: 'Edit Project',
        mergeProject: 'Merge Project',
        trashCan: 'View Project Trash',
        viewGoogleMap: 'View Google Map',
        unarchiveProject: 'Unarchive Project',
        archiveProject: 'Archive Project',
        downloadAllPhotos: 'Download All Photos',
        integrationActions: 'Integrations',
      },
      mergeProjectModal: {
        willMove: 'Will be moved to the other project:',
        willNotMove:
          'Will NOT be moved to the other project and will be deleted:',
        photos: 'Photos',
        documents: 'Documents',
        projectConversation: 'Project Conversation',
        projectNotepad: 'Description',
        projectLabels: 'Project Labels',
        projectShowcase: 'Project Showcase',
        todoLists: 'Checklists',
        assignedUsers: 'Assigned Users',
        pages: 'Pages',
        reports: 'Reports',
        deleteThis: 'Delete this project.',
        keepThis: 'Keep this project and move all photos and documents here.',
        modalTitle: 'Merge Projects',
        searchForAProject: 'Search for a project:',
        selectOneToKeep: 'Select ONE to keep:',
        selectProject: 'Select a project from the list above.',
        mergeProjects: 'Merge Projects',
      },
      photoUploadModal: {
        modalInstructions:
          'Drag & drop photos here or click to upload to this project.',
        modalInstructionsCompleted:
          'Your photos have finished uploading and are now processing. They will appear shortly in your project. To continue adding photos, click here or drag & drop additional photos.',
        uploading: 'Uploading %{count} photos…',
        dismissErrors: 'Dismiss',
        modalTitle: 'Add Photos',
        errorMessageTooLarge:
          'Upload failed: %{fileName} size is too large. Please select a different file and try again.',
        errorMessageWrongFormat:
          'Upload failed: %{fileName} format is not accepted. Please select a different file and try again.',
        errorMessageExceedLimit:
          'Upload failed: A maximum of 5 reference images are allowed. Please try again.',
      },
      sharingPermissions: {
        alertMessage: 'Collaborators will see your photos',
        photosAreSeenByCollabs:
          'Collaborators with permission will see your photos',
      },
      projectLabelsContainer: {
        manageLabels: 'Labels',
        labelsMessage: 'Add Labels',
        labelsEditMessage: 'Edit Labels',
      },
      shareActions: {
        shareAllImages: 'Share All Images',
        project: 'Project',
        addGuestUsers: 'Add Guest Access',
        addCollaborators: 'Add Collaborators',
        timeline: 'Timeline',
        disableTimelineLink: 'Disable Timeline Link',
        enableTimelineLink: 'Enable Timeline Link',
        shareTimelineLink: 'Share Timeline Link',
      },
      shareTimelineModal: {
        modalTitle: 'Share Timeline',
        instructions:
          'Use this project timeline link to share with people who would like to stay up-to-date with this project.',
        viewTimeline: 'View Timeline',
      },
    },
    customersRow: {
      trustyCustomers: 'Trusty Customers',
    },
    tasklist: {
      templates: {
        templatesHeader: {
          projectBackLink: 'Templates',
        },
      },
    },
    members: {
      add: {
        title: 'Assign Users',
        titleConfirm: 'Are you sure?',
        titleError: 'Oops.',
        ariaLabel: 'Add Users',
        searchModalSubtitle: 'Add %{companyName} Users or Groups',
        searchUsersLabel: 'Users',
        searchGroupsLabel: 'Groups',
        placeholder: {
          users: 'Enter a User name',
          groups: 'Enter a Group name',
        },
        loadingMessage: 'Searching %{companyName}',
        success: {
          one: 'Review %{count} user',
          other: 'Review %{count} users',
        },
      },
    },
    banner: {
      premiumTrial: 'Get FREE access to Premium features for 14 days',
      seeDetails: 'See Details',
      close: 'Close promotion',
    },
  },
  core: {
    errorBoundaryFallback: {
      heading: 'Uh Oh!',
      message: 'An error has occurred',
    },
    errorResetButton: {
      message: 'Try Again',
    },
    confirmationModal: {
      contentLabel: 'Confirmation',
    },
    forms: {
      wizard: {
        previousStepButton: 'Previous Step',
        photoCount: {
          one: 'Review %{count} selected',
          other: 'Review %{count} selected',
        },
      },
    },
    guestSelect: {
      optionLabel: 'Create guest with email: %{name}',
      placeholder: 'Type email to find or create Guest User',
      noResultsText: 'No results found',
    },
    projectSelect: {
      selectPlaceholder: 'Select a project',
    },
  },
  companyDashboard: {
    userTable: {
      addUserButton: 'Add User',
      deactivatedUsersDisclaimer:
        'Deactivated users are not included in these results.',
      fetchError:
        'Something went wrong trying to load user stats. Please refresh the page and try again.',
      loadingTitle: 'Compiling Stats (this may take a moment)',
      columnHeaderUser: 'User',
      columnHeaderRole: 'Role',
      columnHeaderPhotos: 'Photos',
      columnHeaderFieldsCompleted: 'Fields Completed',
      columnHeaderReports: 'Reports',
      columnHeaderDocuments: 'Documents',
      columnHeaderLatestActivity: 'Latest Activity',
      deactivated: 'Deactivated',
      invited: 'Invited',
      noActivity: 'No activity',
      today: 'Today',
      searchInput: 'Find a user...',
    },
    pageTitle: 'Company Dashboard',
    dataRefreshedNotice:
      'The data on this page is refreshed every 24 hours and is not real-time. Activity taking place at your company today may not be included here until tomorrow.',
    metrics: {
      fieldsCompleted: 'Fields Completed',
      showcases: '%{number} showcases',
      perProject: '%{number}/project',
      fetchError:
        'Something went wrong trying to load company metrics. Please refresh the page\n          and try again.',
    },
    section: {
      overview: 'Overview',
    },
    modalTitle: 'Company Dashboards are here!',
    modal: {
      introMessage: 'Admins and Managers can now see:',
      feature1: 'High-level insights into productivity across the business',
      feature2: 'How each member is using the app',
      feature3: 'And more!',
      upgradeButton: 'Upgrade',
      okButton: 'Okay',
      notNowButton: 'Not Now',
      premiumUpgradeMessage:
        'Upgrade your plan to see how you can improve efficiency!',
      premiumAdMessage:
        'Hey there 👋 Curious to see how crew members across your company are using CompanyCam? Reach out to your admin to upgrade your account and unlock Company Dashboards.',
    },
    dateFilter: {
      yesterday: 'Yesterday',
      week: '1 Week',
      lastThirtyDays: '30 Days',
      lastSixtyDays: '60 Days',
      year: '1 Year',
      allTime: 'All Time',
    },
  },
  navigation: {
    userMenu: {
      showCompanyDashboard: 'Company Dashboard',
      myPhotos: 'My Photos',
      mySettings: 'My Settings',
      organizationDashboard: 'Organization Dashboard',
      companyTitle: 'Your Company',
      companySettings: 'Company Settings',
      corporatePortal: 'Corporate Portal',
      accessTokens: 'Access Tokens',
      billing: 'Billing',
      tags: 'Tags',
      todosMarketplace: 'Community Checklists',
      supportTitle: 'Support',
      tutorials: 'Help Center',
      support: 'Chat with Support',
      signout: 'Sign Out',
    },
    createActionsMenu: {
      newGroup: 'Create Group',
      menuTitle: 'Create',
      newProject: 'Create Project',
      inviteUser: 'Invite Users',
    },
    searchPlaceholder: 'Search',
    menu: {
      projects: 'Projects',
      photos: 'Photos',
      users: 'Users',
      groups: 'Groups',
      reports: 'Reports',
      map: 'Map',
      todosMarketplace: 'Community Checklists',
      showcases: 'Showcases',
      checklistFeed: 'Checklists',
      getFreeStuff: 'Refer for Prizes!',
      referGetPaid: 'Refer, get $$$',
      moreBusiness: 'Earn More Business',
    },
    support: 'Chat with Support',
    company: 'Company',
    resources: 'Resources',
    upgrade: 'Upgrade',
    upgradeValueProp: 'Get video, unlimited projects and more!',
    navLink: {
      templates: 'Templates',
      reports: 'Reports',
    },
  },
  company: {
    dashboard: {
      latest: {
        activityTooltip:
          'Activity types include: Taking photos or videos, adding documents, leaving comments, adding and completing checklist fields, and creating showcases.',
      },
    },
  },
  organization: {
    loadingAccounts: 'Loading accounts…',
    loadingCompanies: 'Loading Companies',
    metricsErrorMessage:
      'There was an error loading data for your organization. Please refresh the page to try again.',
    companiesErrorMessage:
      'There was an error loading your companies. Please refresh the page to try again.',
    noAccountsFoundTitle: 'Shoot.',
    noAccountsFoundMessage: 'No accounts found.',
    accountAdminBadge: 'Account Admin',
    dataRefreshDateInfoMessage:
      '<strong>The data on this screen is refreshed every 24 hours.</strong> Last refreshed: %{date}',
    lastRefreshDateText: 'Last refreshed',
    thirtyDayOverviewTitle: '30-Day Overview',
    featureUtilizationLabel:
      'From <strong>%{date} to today,</strong> your accounts have added',
    metricMetaDataPrimary: '%{count}/project',
    projectMetricMetadata: '%{count} Showcases',
    checklistMetricMetadataSecondary: '%{count}% completed',
    currentUsersTitle: 'Current Users',
    metricBillableUserTitle: 'Billable',
    metricBillableUserMetadataPrimary:
      '<strong>%{count}</strong> in last 30 days',
    metricInactiveUserTitle: 'Inactive',
    metricInactiveUserTooltip:
      'Have not completed an action in the past 30 days',
    metricUnacceptedUserTitle: 'Unaccepted',
    metricDeactivatedUserTitle: 'Deactivated',
    columnHeaderAccount: 'Account',
    columnHeaderbillableUsers: 'Billable Users',
    columnHeaderProjects: 'Projects',
    columnHeaderPhotos: 'Photos',
    columnHeaderChecklists: 'Checklists',
    columnHeaderReports: 'Reports',
    columnHeaderDocuments: 'Documents',
  },
  organizations: {
    organizationTableCell: {
      newUsersCountLabel: {
        one: '%{count} new user added in last 30 days',
        other: '%{count} new users added in last 30 days',
      },
      unacceptedUsersCountLabel: {
        one: '%{count} user has unaccepted invitation',
        other: '%{count} users have unaccepted invitations',
      },
      inactiveUsersCountLabel: {
        one: '%{count} inactive user',
        other: '%{count} inactive users',
      },
    },
    editCompany: {
      loadingAccessibilityLabel: 'Loading company edit form',
      backButtonText: 'Back',
      backToPortal: 'Back to Portal',
      editCompanySuccessMessage:
        'Company edit successful. You may now close this tab and proceed back to the Corporate Portal.',
    },
    forms: {
      account: {
        form: {
          createAccount: 'Create Account',
          saveAccount: 'Save Account',
          cancelAriaLabel:
            'All data you have changed on this page will not be saved.',
          logo: 'Account Logo',
          name: 'Account Name',
          phoneNumber: 'Phone Number',
          email: 'Account email',
          addressLine1: 'Account Address 1',
          addressLine2: 'Account Address 2',
          city: 'City',
          state: 'State',
          postalCode: 'Postal Code',
          country: 'Country',
          timeZone: 'Time Zone',
        },
      },
      user: {
        form: {
          firstName: 'First Name',
          firstNameValidationError: 'You must provide a first name',
          lastName: 'Last Name',
          lastNameValidationError: 'You must provide a last name',
          emailAddress: 'Email',
          emailAddressValidationError: 'You must provide an email address',
          phoneNumber: 'Phone Number',
          phoneNumberValidationError: 'Phone number is not valid',
          password: 'Password',
          passwordValidationError: 'You must provide a password',
          currentPassword: 'Current Password',
          currentPasswordValidationError:
            "Your current password is required to change this user's email or password.",
          userInformation: 'User Information',
          userPermissions: 'User Permissions',
        },
      },
    },
    newCompany: {
      backButtonText: 'Back',
      newCompanySuccessMsg:
        'Company created successfully. You may now close this tab and proceed back to the Corporate Portal.',
    },
    company: {
      backToPortal: 'Back to Portal',
      addCompanySuccessMessage: 'Your information has been saved.',
      editCompany: 'Edit Company',
    },
    user: {
      edit: {
        title: 'Update %{name}',
        activateButtonLabel: 'Activate',
        deactivateButtonLabel: 'Deactivate',
        forceLogoutButtonLabel: 'Force Logout',
        saveButtonLabel: 'Save User',
      },
      form: {
        create: {
          saveButtonLabel: 'Save User',
          errorMessage:
            'There was a problem submitting the form. Please correct the errors\n          below.',
        },
        edit: {
          foldTitle: 'Change Email Address or Password',
          errorMessage:
            'There was a problem submitting the form. Please correct the errors\n          below.',
        },
        passwordRequiredFold: {
          resetMessage:
            "Don't know your password? <resetlink>Log out and reset it</resetlink>",
          message:
            "Confirm <strong>your current password</strong> (not this user's\n              current password) to edit account info.",
        },
      },
      invitationForm: {
        title: 'Add a User',
        sendEmailButtonLabel: 'Send Email',
        sendSMSButtonLabel: 'Send SMS',
        skipButtonLabel: 'Skip',
        loadingAccessibilityLabel: 'Loading invitation options',
      },
      addUserSuccessMessage:
        '<p><b>Your information has been saved.</b> %{name} has been added to %{orgName}.</p><p><b>Username: %{emailAddress}.</b></p><p><b>You can now close this tab and proceed back to Corporate Portal.</b></p><p><b>%{br}Download the app here: %{downloadLink}</b></p>',
      addUserPreviewMessage:
        "<p>Hey %{name}, you've been added to CompanyCam. Download the app here: %{downloadLink}</p><p>Username: %{emailAddress}</p>",
      list: {
        header: 'Users',
        addUserLink: 'Add User',
        deactivatedBadge: 'deactivated',
      },
      new: {
        title: 'Add a User',
        saveButtonLabel: 'Add User',
        back: 'Back',
      },
    },
    userEdit: {
      loadingAccessibilityLabel: 'Loading user edit form',
      editUserSuccessMessage:
        'Your information has been saved. You may now close this tab and proceed back to the Corporate Portal.',
      forceLogoutSuccessMessage:
        'User has been logged out. You may now close this tab and proceed back to the Corporate Portal.',
      deactivateUserSuccessMessage:
        'User has been deactivated. You may now close this tab and proceed back to the Corporate Portal.',
      activateUserSuccessMessage:
        'User has been activated. You may now close this tab and proceed back to the Corporate Portal.',
    },
  },
  documents: {
    document: {
      manageAccess: {
        title: 'Manage Access',
      },
      save: 'Save',
      creator: '%{creator}',
      rename: 'Rename',
      addons: 'Add-Ons',
      requestsignature: 'Request Signatures',
      requestSignatureFileLimitation: 'Files over 40MB cannot be signed',
      signatures: 'Signatures',
      cancelSignatureRequest: 'Cancel Signature Request',
      connectingdropbox: 'Connecting to Dropbox Sign',
      signaturesPending: 'Signature pending',
      signaturesSigned: 'Signed',
      signaturesError: 'Signature error',
    },
    index: {
      fromComputer: 'From Computer',
      fromCompanyDocuments: 'Use Existing Template',
      addDocuments: 'Upload Documents',
      searchPlaceholder: 'Find a document…',
      newestFirst: 'Newest First',
      oldestFirst: 'Oldest First',
      emptyTitle: 'Keep Files Organized',
      emptyMessage: 'Scan and upload documents, receipts, and more.',
      noResults: 'No results!',
      collabNotificationStrong:
        'Documents are not shared between collaborators.',
      collabNotification: 'Documents you add are only seen by %{companyName}.',
      order: 'Order',
    },
    companyDocument: {
      manageDocuments: 'Manage Documents',
      emptyMsg: 'Streamline your projects with Project Documents',
      addDocumentButton: 'Add Document',
      addsDocuments: 'Add Documents',
      addToProject: 'Add to Project',
      editTitle: 'Edit Document Title',
    },
    documentViewer: {
      downloadUnsigned: 'Download Unsigned',
      loadingFailed: "We're having trouble loading your document.",
      downloadError: 'There was a problem downloading this document',
      error: {
        heading: 'Uh Oh!',
        noaccess: {
          heading: 'Restricted Document',
          subheading:
            'Only the uploader, admins and managers can see this document.',
          backToProjectButton: 'Back to Project',
        },
      },
      load: {
        error: {
          message: 'An error has occurred',
        },
      },
      errorResetButton: 'Try Again',
    },
    deleteDocumentModal: {
      confirmationMessage: 'Are you sure you want to delete this document?',
      title: 'Delete Document',
      secondaryConfirmation: 'Keep an unsigned copy of the document',
    },
    delete: {
      rejectCancel: "No, don't cancel",
      confirmCancel: 'Yes, cancel',
      success: 'Document deleted',
      error: 'There was a problem deleting this document.',
    },
    upload: {
      maxfilesizesignatures:
        "If you'd like to get this document signed, upload as a .pdf or .docx file under 40MB and less than 500 pages.",
      addDocuments: 'Add Documents',
      maxfilesize: 'Maximum file size of %{MAX_SIZE}MB.',
      success: 'All of your accepted files have successfully uploaded!',
      uploadMessaging: 'Uploading %{count} documents…',
      dragAndDropMessage:
        'Drag & Drop document here or click to upload to this project.',
    },
    altText: 'Image of a set of documents.',
    cancelSignatureRequestModal: {
      message:
        'Your document will be saved in its original state without signatures',
      title: 'Cancel Signature Request?',
    },
    cancelSignatureRequest: {
      success: 'Signature Request Cancelled',
      error: 'There was a problem cancelling this signature request',
    },
    requestSignatureRequest: {
      error: 'There was a problem creating this signature request',
    },
    sendSignatureRequest: {
      success: 'Signature Request Sent!',
    },
    singleShareModal: {
      shareDocument: 'Share Document',
    },
    addFromCompanyDocuments: {
      errorMessage:
        'There was a problem adding the document. Please try again.',
    },
  },
  filters: {
    noResultsFound: 'No %{filterType} found',
    labelQueryTerm: 'Label Query Term',
    tagQueryTerm: 'Tag Query Term',
    userQueryTerm: 'User Query Term',
    groupQueryTerm: 'Group Query Term',
    projectQueryTerm: 'Project Query Term',
    lastUpdated: 'Last Updated %{date}',
  },
  galleries: {
    configuration: {
      instructions: 'Include who, when, and where the photo was taken.',
      video: {
        submit: 'Get Link to Video',
      },
      submit: 'Get Link to Gallery',
    },
    galleryModal: {
      shareGallery: 'Share Gallery',
    },
    share: {
      video: {
        viewGallery: 'View Video',
      },
      viewGallery: 'View Gallery',
    },
  },
  videos: {
    singleShareModal: {
      shareVideo: 'Share Video',
    },
    singleVideoDownloadModal: {
      downloadVideo: 'Download Video',
    },
    singleDownloadModal: {
      modified: "You may need to right click and choose 'Save link as...'",
    },
  },
  groups: {
    index: {
      title: 'Groups',
    },
  },
  invitations: {
    form: {
      inviteUsers: 'Invite Users',
      sendInvite: 'Send Invite',
      firstName: 'First Name',
      lastName: 'Last Name',
      userDetails: 'User Details',
      inviteNewUser: 'Invite New User',
      setRolesAndSend: 'Set Roles and Send',
      emailHelperText: 'Press enter to add',
    },
    permissions: {
      userPermissions: 'User Permissions',
    },
    review: {
      body1: "Set each user's role.",
      body2: "Don't worry, this can be changed later.",
      sendInvites: {
        one: 'Send %{count} Invite',
        other: 'Send %{count} Invites',
      },
    },
    stepper: {
      stepOne: 'Add Emails',
      stepTwo: 'Review Invites',
      stepThree: 'Done!',
    },
    validation: {
      emailPlaceholder: 'Type or paste emails and hit enter',
      emailInUse: '%{email} is not a valid email address.',
      duplicateEmail: '%{email} has already been added.',
      duplicate: 'Email address is a duplicate',
      taken: 'Email address has already been taken',
      invalid: 'Email address is invalid',
      unknown:
        'Please try again. If the problem persists, please contact support.',
    },
    new: {
      reviewPlaceholder: 'Review Invites',
      reviewInvites: {
        one: 'Review %{count} Invite',
        other: 'Review %{count} Invites',
      },
      noticeBillableUsers: 'Invited users will be added to your bill.',
      noticeInTrial: "Paid subscriptions start with 3 seats, so fill 'em up!",
      noticeOutOfTrial: 'New users will be added to your monthly bill.',
      noticeOpenSeats: {
        one: 'Review %{count} open seat',
        other: 'Review %{count} open seats',
      },
      itunesOpenSeats: {
        one: 'Review %{count} open seat',
        other: 'Review %{count} open seats',
      },
    },
    backModal: {
      title: "You're about to leave.",
      message:
        'If you leave, your progress will not be saved and you will have to start over.',
      confirmLabel: 'Yes, leave',
      cancelLabel: 'No, keep inviting',
    },
    notice: {
      itunesMaxUsers:
        'Choose a different plan to continue adding users to your account.',
      seePlans: 'See Plans',
    },
    error: 'There was an error sending invitations',
    index: {
      resentMessage: 'The invitation has been resent',
    },
    invitations: {
      manuallyAcceptInvite: 'Manually Accept Invite',
    },
  },
  invitationSteps: {
    review: {
      inviteAnother: 'Invite Another User',
    },
  },
  labels: {
    labelsModal: {
      createLabel: 'Create Label',
      modalTitle: 'Delete %{displayValue}',
      deleteConfirmationInstructions:
        'Are you sure you want to delete this label?',
      deleteCompanyWideWarning:
        'The label will be removed company-wide and will no longer be available for future projects.',
      deleteProjectWideWarning:
        "It will be deleted from all projects where it's currently applied.",
      editForm: 'Edit Label',
      nameErrorMsg: 'Give the label a name',
      chooseColorErrorMsg: 'Choose a color for your label',
      moreOptions: 'More Options',
      noLabelSelected: 'No labels selected',
      searchLabels: 'Search Labels',
      tryAnotherTerm: 'Please try another search term.',
      noLabelsFound: 'No Labels Found',
    },
    labelForm: {
      nameLabel: 'Name',
      colorLabel: 'Color',
      save: 'Save Label',
      delete: 'Delete Label',
      placeholder: 'Give your new label a name...',
    },
    labelsContainer: {
      modalTitle: 'Project Labels',
      createButton: 'New Label',
      saveButton: 'Save',
    },
  },
  map: {
    mapContainer: {
      recenterMapAriaLabel: 'Recenter Map',
      placePinAriaLabel: 'Place Pin',
      drawGeofenceAriaLabel: 'Draw Geofence',
      searchPlaceholder: 'Search for an address',
      dragLabel: 'Drag %{type} to move or right-click to delete.',
    },
    filters: {
      noResultsFound: 'No %{filterType} found',
      labelQueryTerm: 'Label Query Term',
    },
    geolocation: {
      alert: 'Geolocation is not supported by your browser.',
    },
    locationModal: {
      title: 'Location Access Denied',
      strong: {
        title: 'To enable location access, follow these steps',
        chrome: 'Chrome:',
        safari: 'Safari:',
        firefox: 'Firefox:',
      },
      chromeInstructions:
        'Go to Settings → Privacy & Security → Site Settings → Location → Allow access.',
      safariInstructions:
        'Go to Preferences → Websites → Location → Allow access.',
      firefoxInstructions:
        'Click the lock icon → Permissions → Enable location access.',
    },
  },
  viewerFavorites: {
    starredItems: {
      emptyState:
        'Star projects, users, and groups for easy access from any page.',
      header: 'Starred Items',
      limitInfo:
        "There is a max limit of 50 starred items that can be displayed in\n              this list. You'll have to unstar some items to ensure all of\n              them always be visible.",
    },
  },
  notifications: {
    common: {
      title: 'Notifications',
      markAsRead: 'Mark as Read',
      markAsUnread: 'Mark as Unread',
    },
    badge: {
      view: 'View Notifications',
      hide: 'Hide Notifications',
      viewAll: 'View All',
    },
    emptyState: {
      title: 'No notifications yet',
      message:
        "When you have new updates from your team,  they'll show up here.",
    },
    markAllAsRead: 'Mark all as read',
    markAssignmentsAsRead: 'Mark assignments as read',
    markMentionsAsRead: 'Mark mentions as read',
    markCommentsAsRead: 'Mark comments as read',
    filters: {
      all: 'All',
      assignments: 'Assignments',
      mentions: 'Mentions',
      comments: 'Comments',
    },
    settings: {
      link: {
        title: 'Notification Settings',
      },
    },
  },
  notificationSettings: {
    link: {
      title: 'Notification Settings',
    },
  },
  onboard: {
    demo: {
      createFirstProject: 'Create First Project',
    },
    loading: {
      message: 'Preparing your unlimited cloud storage...',
    },
    common: {
      installMobileApp: 'Install Mobile App',
    },
    signUp: {
      industry: {
        prompt: 'What do you do?',
        skip: 'Skip',
        selectOne: 'Select one...',
      },
      button: 'Continue',
      title: 'Welcome! Create an account for your company.',
      collabTitle: "Create your company's account to accept the collaboration.",
      mobileTitle: 'Create an account for your company.',
      mobileButton: 'Create Account',
      collabButton: 'Create Company',
    },
    usersForm: {
      emailLabel: 'Team Member Email',
      title: 'Invite Team Members',
      message:
        "Get the most out of CompanyCam by adding team members. We'll email each user a link to get started.",
      addAnotherEmail: 'Add More',
      sendInvites: 'Invite Team Members',
      addTeamLater: {
        '': 'Maybe Later',
      },
    },
    terms: {
      description:
        'I agree to <tou>Terms of Use</tou> and <privacy>Privacy Policy</privacy>.',
    },
    formValidationEmailUnique: 'Email already taken',
    formValidationFirstNameCharacterLimit:
      'First name must be less than 50 characters',
    formValidationFirstNameRequired: 'First name is required',
    formValidationLastNameCharacterLimit:
      'Last name must be less than 50 characters',
    formValidationLastNameRequired: 'Last name is required',
    formValidationCompanyNameCharacterLimit:
      'Company name must be less than 80 characters',
    formValidationCompanyNameRequired: 'Company name is required',
    formValidationPhoneNumberInvalid: 'Phone number is invalid',
    formValidationPhoneNumberCharacterLimit:
      'Phone number must be less than 50 characters',
    formValidationPhoneNumberRequired: 'Phone number is required',
    formValidationEmailInvalid: 'Email address is invalid',
    formValidationEmailCharacterLimit:
      'Email address must be less than 50 characters',
    formValidationEmailRequired: 'Email address is required',
    formValidationPasswordShort: 'Password must be at least 10 characters',
    formValidationPasswordLong: 'Password must be less than 100 characters',
    formValidationPasswordCharTypes:
      'Password must have one uppercase letter and one lowercase letter',
    formValidationPasswordRequired: 'Password is required',
    formValidationIndustryRequired: 'Industry is required',
    formValidationRoleRequired: 'Role is required',
    formValidationFieldRequired: 'This field is required',
    stepper: {
      step1: 'Create Company Account',
      step2: 'Complete Your Profile',
    },
    brandMessage:
      'Join 20,000+ companies who rely on CompanyCam for communication and accountability.',
    quotePartOne:
      'CompanyCam has definitely helped our communication, without a doubt.',
    quotePartTwo: 'Everything in this business is urgent',
    quotePartThree:
      '; it’s about how fast you can put your eyeballs on a situation and come up with a solution.',
    userName: 'Brad Smith',
    userCompany: 'Brad Smith Roofing',
    backButton: {
      text: 'Back to %{prev}',
    },
    myProfile: {
      title: 'Take a second to finish up your profile.',
      titleCollab:
        'Take a second to finish up your profile. Then get right to work on the project!',
      createProfile: 'Create Profile',
    },
  },
  payments: {
    setupStripe: {
      title: 'Collect Payments',
      description: 'Type a number, send a link, and get paid.',
      finishSetup: 'Finish Setup',
      setUpStripe: 'Set Up Stripe',
      learnMore: 'Learn More',
      askPermission: 'Ask a manager or admin to set up payments.',
      checklist: {
        one: 'ACH, credit card, Google Pay, or Apple Pay',
        two: 'Customers can pay online or in Trusty app',
        three: '1% fee for ACH, 3% for everything else',
      },
      requestAccessButton: 'Request Access',
    },
    header: {
      create: 'Payment Request',
    },
    error: {
      heading: 'Uh Oh!',
      message: 'An error has occurred',
    },
    buttonLabel: 'Try Again',
    common: {
      convertToDraft: 'Convert to Draft',
      payouts: 'Payouts',
      requestPayment: 'Request Payment',
      paymentRequest: 'Payment Request',
      saveAsDraft: 'Save as Draft',
      create: 'Create',
    },
    valuePropsModal: {
      title: 'Payments',
      description: 'Get paid quickly and easily.',
      valueProp1: 'ACH, credit card, Google Pay, or Apple Pay',
      valueProp2: 'Customers can pay online or in Trusty app',
      valueProp3: '1% fee for ACH, 3% fee for everything else',
    },
  },
  paymentRequest: {
    useNewPaymentRequest: {
      success: 'Payment request created.',
      error: 'Could not create this payment request.',
    },
    modal: {
      createAndShare: 'Create & Share',
      create: {
        title: 'New Payment Request',
      },
      titlePlaceholder: 'Payment Request #%{count}',
      total: 'Total',
      totalPlaceholder: '$0.00',
      memo: 'Memo',
      memoPlaceholder: "What's this payment for?",
      titleRequired: 'Title required.',
      totalRequired: 'Amount must be at least $0.50 and less than $10,000.00.',
      statusHeader: 'Status',
      clipboardTitle: 'Share Link',
      update: {
        title: 'Edit Payment Request',
      },
    },
    useUpdatePaymentRequest: {
      success: 'Payment request updated.',
      error: 'Could not update this payment request.',
    },
    copyLink: {
      button: 'Copy Link',
      success: 'Link Copied to Clipboard',
      error: 'Could not copy this text.',
    },
  },
  paymentBadgeStatus: {
    status: {
      failedLabel: 'Payment Failed',
      sharedLabel: 'Shared',
      draftLabel: 'Draft',
      refundedLabel: 'Refunded',
      refundPendingLabel: 'Refund Pending',
      paidLabel: 'Paid',
      processingLabel: 'Payment Processing',
    },
  },
  paymentRequestList: {
    table: {
      emptyTitle: 'Type a Number, Get Paid',
      emptyMessage: 'Request and collect customer payments, powered by Stripe.',
      emptyImageAlt:
        'Image depicting a payment request form and a credit card.',
    },
    tableHeader: {
      title: 'Title',
      amount: 'Amount',
      status: 'Status',
    },
  },
  paymentRequestListItem: {
    toast: {
      refundProcessing: 'Refund processing.',
    },
    refundPaymentError: 'Could not refund payment.',
    confirmRefundAction: 'Refund',
    confirmRefundMessage:
      "We'll use your Stripe balance to provide a full refund of %{formattedAmount} to the customer. Please note, any processing fees from the original transaction will not be refunded to you.",
    confirmRefundTitle: 'Refund Payment',
    deletePaymentRequestTitle: 'Delete Payment Request',
    deletePaymentRequestMessage:
      'Access to this payment request will be lost. You cannot undo this action.',
    deletePaymentRequestSuccess: 'Deleted payment request.',
    deletePaymentRequestError: 'Could not delete this payment request.',
    shareButtonTooltip:
      'Amount must be at least $0.50 and less than $10,000.00.',
  },
  paymentRequestOptionsMenu: {
    issueRefundOption: 'Issue Refund',
  },
  paymentRequestStatusBadge: {
    status: {
      failedLabel: 'Payment Failed',
      sharedLabel: 'Outstanding',
      draftLabel: 'Draft',
      refundedLabel: 'Refunded',
      refundPendingLabel: 'Refund Pending',
      paidLabel: 'Paid',
      processingLabel: 'Payment Processing',
    },
  },
  valueProps: {
    beforeAfter: {
      title: 'Before & After Cam',
      description:
        'Combine your before and after photos into a single image that shows off your work.',
      valuePropA: 'Choose photos from your projects',
      valuePropB: 'Pick a layout from our template gallery',
      valuePropC: 'Brand your image with your logo',
      valuePropD: 'Share on your website or social pages',
    },
    collabChecklists: {
      title: 'Collaborate on Checklists',
      description: 'Get it organized. Get it done.',
      valuePropA:
        'The project owner shoots you a Checklist. You knock it out, get paid, and go home!',
      valuePropB:
        'Keep your people focused on what they need to do, while you track their progress.',
      valuePropC:
        'Avoid "oh crap, I forgot to do that" mistakes. Hey, we all make \'em.',
    },
    gallerylinks: {
      title: 'Gallery Sharing',
      description:
        'Select specific images or share every photo in a project with a single link.',
      valuePropA: 'Choose whether or not to include details',
      valuePropB: 'Generate a link to send via text or email',
      valuePropC: "Send to anyone, even if they don't have the app",
    },
    projectCollaboration: {
      title: 'Project Collaboration',
      description:
        'Invite subcontractors or other companies to join your projects in CompanyCam.',
      valuePropA: 'Instantly access their photos and videos',
      valuePropB: 'Include them in the project conversation',
      valuePropC: 'Add unlimited collaborators',
    },
    reports: {
      title: 'Photo Reports',
      description:
        'Create clean, customized reports that you can share from anywhere.',
      valuePropA: 'Choose your layout and drop in photos',
      valuePropB: 'Customize with project information',
      valuePropC: 'Save your report as a PDF',
      valuePropD: 'Generate a shareable link in seconds',
    },
    taskListAssign: {
      title: 'Assign Checklists',
      description: 'Keep your crew accountable and productive',
      valuePropA: 'Assign a checklist to one or more users on your account',
      valuePropB:
        "Assigned users get a notification on their device, letting them know there's work to do",
      valuePropC:
        "Avoid 'oh, crap, I forgot to do that' mistakes. Hey, we all make 'em",
    },
    timelinelinks: {
      title: 'Timeline Sharing',
      description:
        'Send a project link that lets crew members and customers see photos as you add them.',
      valuePropA: 'Show every job photo in chronological order',
      valuePropB: 'Generate a link to send via text or email',
      valuePropC: "Share with anyone, even if they don't have the app",
    },
    todos: {
      title: 'Checklists',
      description: 'Get it organized. Get it done.',
      valuePropA: 'Easily create punch lists within your projects.',
      valuePropB:
        'Keep your people focused on what they need to do, while you track their progress',
      valuePropC:
        'Avoid "oh crap, I forgot to do that" mistakes. Hey, we all make \'em.',
    },
    shared: {
      freePremiumTrial: 'Try Premium for Free',
      upgradeToPro: 'Upgrade',
      dismiss: 'Got it',
    },
  },
  photos: {
    deletePhotosModal: {
      confirmMoveToTrash: 'Yes, Move to Trash',
      confirmationMessageTrash:
        'Photos will be deleted 90 days after you move them to the Trash.',
      confirmationMessageTrashSecondary:
        'Users with Admin or Manager permissions can move them back to the project within 90 days.',
      confirmationMessage: {
        one: 'Review %{count} photo',
        other: 'Review %{count} photos',
      },
      modalTitle: {
        one: 'Review %{count} Photo',
        other: 'Review %{count} Photos',
      },
      deletePhotos: 'Delete Photos',
    },
    downloadModal: {
      pendingTitle: 'Download',
      instructions:
        "Enter your email, and we'll notify you when your download is ready. If you'd prefer to wait, your link will also appear here when ready.",
      packagingTitle: 'Packaging Photos',
      packagingInstructionsEmail:
        "We are packaging your photos and will email them to %{email}. Your link will also appear here when ready if you'd like to wait.",
      packagingInstructionsNoEmail:
        'We are packaging your photos. Your link will appear here when ready.',
      completedTitle: 'Your download is ready!',
      downloadPhotos: 'Download Photos',
      videoMessage: {
        withPhotos: {
          one: "We know it's not ideal, but the selected videos can't be included in your download. On the bright side, the photo you've selected will be included.",
          other:
            "We know it's not ideal, but the selected videos can't be included in your download. On the bright side, the photos you've selected will be included.",
        },
        withoutPhotos:
          "We know it's not ideal, but you'll need to download videos one at a time.",
      },
    },
    printModal: {
      modalTitlePrintPhotos: 'Print Photos',
      instructions: 'Include who, when, and where the photo was taken.',
      '1perpage': '1 per page',
      '2perpage': '2 per page',
      '4perpage': '4 per page',
      '10perpage': '10 per page',
      printPhotos: 'Print Photos',
    },
    reassignPhotosModal: {
      title: 'Move Photos',
      instructions:
        'Select which project you would like to move the selected photos to.',
      movePhotos: 'Move Photos',
    },
    singleDownloadModal: {
      modalLabel: 'Photo download modal',
      downloadPhoto: 'Download Photo',
      downloadInprogress: 'Download in progress',
      modified:
        'Looks like this photo has been modified. Which version would you like to download?',
      downloadPending: 'Your download will be started shortly',
    },
    singleShareModal: {
      sharePhoto: 'Share Photo',
      instruction: 'Which version would you like to share?',
      small: 'Small',
      medium: 'Medium',
      large: 'Large',
      viewPhoto: 'View Photo',
    },
  },
  projectMap: {
    expandProjectButton: {
      viewProject: 'View Project',
    },
    filterbar: {
      dateSelector: {
        filterByDate: 'Filter By Date',
        from: 'From',
        to: 'To',
        clear: 'Clear',
        labelToday: 'Today',
        labelThisWeek: 'This Week',
        labelThisMonth: 'This Month',
        labelThisYear: 'This Year',
        labelLast30Days: 'Last 30 Days',
        labelCustom: 'Custom',
      },
    },
    filterBar: {
      dateRange: 'Date Range',
      usersAndGroups: 'Users and Groups',
      infoSection: {
        refiningResults: 'Refining results…',
        zoomToRefineResults: 'Zoom to refine results',
        count: 'Viewing %{currentResultCount} of %{totalResultCount}',
      },
    },
    projectMap: {
      pageTitle: 'Project Map',
      clearSearchLabel: 'Clear search results',
    },
    projectMarker: {
      lastImageTaken: {
        today: 'today',
        yesterday: 'yesterday',
        lastImageTaken: 'Latest photo %{date}',
      },
      projectMarker: {
        details: 'This project does not have any photos',
      },
    },
    sidebar: {
      loadingAccessibilityLabel: 'Loading projects',
      errorHeading: 'Something Went Wrong',
      errorMessage: "This page didn't load correctly. Try refreshing.",
      emptyStateHeading: 'No Results Found',
      emptyStateMessage: 'Try searching a different project.',
      projectCountPlural: '%{projectCount} results',
      projectCountSingular: '%{projectCount} result',
    },
  },
  projectView: {
    projectView: {
      noPhotos: 'This project does not have any photos',
    },
  },
  projectPage: {
    assetPicker: {
      title: 'Select Photos to Add',
      addPhotos: 'Add Photos',
      addPhoto: 'Add Photo',
    },
    photoPicker: {
      title: 'Select Photo',
      addPhotos: 'Add Photos',
      addPhoto: 'Add Photo',
    },
    pages: {
      createPage: 'Create Page',
      watchVideo: 'Watch 1 min Demo',
      loadMore: 'Load More',
    },
    hooks: {
      createError: 'This was an error creating the project note page',
      deleteProjectPageSuccess: 'Deleted!',
      deleteProjectPageError:
        'This was an error deleting the project note page',
      shareProjectPageError: 'This was an error sharing the project note page',
      updatePageError: 'This was an error updating the project note page',
      updateTitleError: 'There was an error updating the page title',
    },
    projectPageList: {
      emptyTitle: 'Untitled',
      emptyPreview: 'This page is empty',
      column: {
        title: 'Page Title',
        dateCreated: 'Date Created',
        latestActivity: 'Latest Activity',
      },
    },
    transcript: {
      alertCopied: 'Copied to Clipboard',
      title: 'Transcript',
      copyTranscript: 'Copy Transcript',
    },
    changesSaved: {
      success: 'Changes Saved',
      edited: 'Edited',
    },
    deleteModal: {
      title: 'Delete Page',
      body: 'Are you sure you want to delete this page? You cannot undo this action.',
    },
    viewTranscript: 'View Transcript',
    closeTranscript: 'Close Transcript',
    saveAsTemplate: 'Save as a New Template',
    shareAction: {
      sharePage: 'Share Page',
      sharePageDescription: 'Anyone with this link can view the page',
    },
    saveAsTemplateModalTitle: 'Save Page as Template',
    descriptionLabel: 'Description (Optional)',
    saveAsTemplateNoticeMessage:
      'Heads up! Photos on this Page will not be included on the saved Template.',
    usePageTemplateModal: {
      title: 'Choose Page Template',
      emptyStateImageAlt: "An image of pages that can't be found",
      emptyStateMessage:
        'Create a page and save it as a template to find it here.',
      exampleTemplates: 'Example Templates',
      onboardTooltipMessage:
        'Save this Page as a template for quick access and easy reuse in future projects.',
    },
    toast: {
      saveAsTemplateSuccess: 'Template Saved',
      saveAsTemplateAction: 'View Templates',
    },
    getPageError: 'Something went wrong while loading your page.',
    backToProject: 'Back to Project',
  },
  pages: {
    blankPage: 'Blank Page',
    useTemplate: 'Use Existing Template',
    examplesListTitle: 'Start with an Example',
    filters: {
      exampleTemplates: 'Example Templates',
      yourCompany: 'Your Company',
    },
    examples: {
      materialsList: 'Materials List',
      dailyLog: 'Daily Log',
      estimate: 'Estimate',
      workOrder: 'Work Order',
      invoice: 'Invoice',
      invoiceBannerAction: 'Open Survey',
      invoiceBannerMessage:
        'Help us improved the invoice template by completing a short survey.',
    },
    emptyTitle: 'Your New Digital Notebook',
    emptyMessage:
      'Capture freeform details of the job site to keep everyone on the same page.',
    altText: 'Image of a set of pages with a task item and images on each.',
    collabNotificationStrong: 'Pages are not shared between collaborators.',
    collabNotification: 'Pages you add are only seen by %{companyName}.',
    toolbar: {
      addImage: 'Add Image',
      paragraph: 'Paragraph',
      heading1: 'Heading 1',
      heading2: 'Heading 2',
      heading3: 'Heading 3',
      layoutPopoverTitle: 'Insert',
      layoutsPopoverCoverPage: 'Cover Page',
      layoutsPopoverCoverPageLabel: 'Cover Page',
      layoutsPopoverHorizontal: 'Horizontal',
      layoutsPopoverImageLeftLabel: 'Horizontal layout with image left',
      layoutsPopoverImageRightLabel: 'Horizontal layout with image right',
      layoutsPopoverVertical: 'Vertical',
      layoutsPopoverTwoColumnLabel: 'Vertical layout with two columns',
      layoutsPopoverThreeColumnLabel: 'Vertical layout with three columns',
      textAndImageLayouts: 'Text & Image Layouts',
      addTable: 'Add Table',
      addRowBefore: 'Add Row Before',
      addRowAfter: 'Add Row After',
      deleteRow: 'Remove Row',
      addColumnBefore: 'Add Column Before',
      addColumnAfter: 'Add Column After',
      deleteColumn: 'Remove Column',
      deleteTable: 'Delete Table',
    },
    linkDialog: {
      link: 'Link',
      text: 'Text',
      removeLink: 'Remove Link',
    },
  },
  project: {
    collaboration: {
      endCollaborationMenuItem: 'End Collaboration',
    },
    contacts: {
      errorSaving: 'Error Saving Contact',
      formNameLabel: 'Name',
      formAddContactButtonText: 'Add Contact',
      formRemoveContactButtonText: 'Remove Contact',
      formNameRequired: 'Contact name is required',
      formNameTooShort: 'Name must be at least 2 characters',
      formPhoneOnlyNumbers: 'Phone number can only contain numbers',
      formPhoneTooShort: 'Phone number must be at least 9 numbers',
      formPhoneTooLong: 'Phone number cannot be more than 15 numbers',
      formEmailInvalid: 'Email must be a valid email address',
      projectContact: 'Contact Info',
      editProjectContact: 'Edit Contact Info',
      removeProjectContact: 'Remove Project Contact',
      confirmRemoveContact:
        'Are you sure you want to remove this project contact?',
      yesRemove: 'Yes, Remove',
    },
    qrcode: {
      download: 'Download QR Code',
    },
    addPhotos: 'Add Photos',
    noteEditor: {
      confirmDiscard:
        'This will discard any changes you have made.  Are you sure?',
      placeholder: 'Enter project description here',
    },
    taskList: {
      assign: {
        errorMessage: 'There was a problem fetching the assigned users!',
      },
      emptyTaskMsg: 'Add some fields to get started.',
      newSectionTip:
        'If you want to organize your fields into sections, you can start by clicking the New Section button below.',
      addTask: 'Add Field',
      newSection: 'New Section',
      emptyTaskRestrictedMsg: 'No fields have been added yet.',
      error: 'Something went wrong when loading your checklist.',
      subTasks: {
        deleteTitle: 'Delete Question',
        deleteMessage: 'Are you sure you want to delete this question?',
        update: 'There was an error editing the question.',
        updateAnswerChoice: 'There was an error selecting an answer',
        updatePosition: {
          error: 'There was an error rearranging questions.',
        },
        dragIconTitle: 'Icon to drag and sort questions',
        multiSelect: {
          multiAnswer: 'Multi Answer',
          sngleAnswer: 'Single Answer',
          placeholder: 'Add an Option',
        },
        new: 'Question',
        taskType: {
          yesNo: 'Yes/No',
          ratingScale: 'Rating',
          textQuestion: 'Text',
          multiSelect: 'Multiple Choice',
        },
        label: {
          openTextAnswerInputPlaceholder: 'Add Response...',
          openTextAnswerInputLabel: 'Text Answer Input',
          placeholder: 'Description (optional)',
          selectAll: '(select all that apply)',
        },
      },
    },
    tasklists: {
      assign: {
        searchInputPlaceholder: 'Search for Users',
        searchErrorMessage: 'Error loading users',
        searchNoResultsMessage: 'No users found.',
        searchInProgressMessage: 'Finding your people...',
      },
      collaboratorNotice: {
        collaboratorsHaveAccess: 'can collaborate on Checklists',
        collaboratorPlanNotice:
          'Collaborators must be on a paid CompanyCam plan in order to interact with checklists.',
        collaboratorsShouldUpdate:
          'You can now share Checklists with your collaborators.',
      },
    },
    reviews: {
      customerFlow: {
        copyReviewButton: 'Copy and Open Google Reviews',
        emailVerify: 'Verify your email address',
        improveClosing:
          'We appreciate your business and you taking the time to provide us some honest feedback.',
        improveClosingTitle: 'Thank You!',
        improveLabel: 'How could we improve?',
        improvePlaceholder:
          'Please let us know how we fell short of your expectations.',
        notUsedPubliclyDisclaimer: 'This will not be used publicly.',
        poweredBy: 'Powered by',
        pubReviewClosingTitle: 'Help us work with more customers like you.',
        pubReviewClosing:
          'Copy your review and paste it into a Google review for us.',
        pubReviewLabel: 'Write a public review',
        pubReviewPlaceholder:
          'This helps new customers feel confident moving forward with us.',
        starRatingAccessibilityLabel:
          'Rate your experience from one to five stars',
        starRatingSummaryAccessibilityLabel: '%{starRating} stars selected',
        starRatingTitle: "Overall, how'd we do?",
        yourName: 'Your Name',
      },
      reviews: 'Reviews',
      requestReview: 'Request Review',
      viewReviewsHub: 'View Reviews Hub',
      optIn: 'Get Early Access',
      optInSuccessTitle: 'Your company has early access',
      optInSuccessContent:
        'Your company will enjoy early access to review features as we roll them out, allowing you to provide valuable feedback that will enhance the experience for all CompanyCam users. For additional information, you can find resources in the Reviews Hub.',
      feedbackThanks: 'Thanks for your feedback',
      joinedBetaTitle: 'Thanks for joining the BETA.',
      joinedBetaContent:
        "We're excited to have you on board as we work to improve the way contractors collect feedback. To stay up-to-date with the latest updates, share your feedback, and get important information, the Reviews Hub will be your go-to place for all things related to the beta program.",
      feedback: {
        question: "What's the hardest part of getting a review for you?",
        option1: 'Remembering to ask for it',
        option2: 'Unsure of whether they will give me a good review',
        option3: 'Remembering if I have already asked or not',
        option4:
          'Not sure what information to give a homeowner to get a good review',
      },
      messageRequired: 'Message is required',
      messageLabel: 'Write a message',
      choosePhotosLabel: 'Choose photos to share',
      messagePlaceholder: 'Enter a message',
      urlCopy: 'Copy link and share',
      previewHelper: 'This is a preview of what your customer will receive.',
      builderTitle: 'Request a review for %{projectName}',
      feedbackCta: 'Rate Your Experience',
      feedbackTitle: "We'd love your feedback!",
      feedbackClose: 'Thanks,',
      assetSelectorTitle: 'Choose Photos',
      assetSelectorHeadline: 'No Photos Available',
      assetSelectorMessage:
        'There are no photos available to add to the review request.',
      reviewProfile: {
        confirmBusinessQuestion: 'Is this your company?',
        confirmBusinessButton: 'Yes, this is my company',
        title: 'Setup your profile to start collecting reviews',
        instructions: 'Search for your company to sync information from Google',
        poweredByGoogle: 'Powered by Google',
        googleLinkSuccess: 'Google Business Profile linked successfully',
        googleLinkError: 'Failed to link Google Business Profile',
      },
      reviewsHub: {
        noReviewsTitle: 'Build Your Reputation',
        noReviewsMessage:
          'Head to one of your finished projects to request a review from the customer.',
        project: 'Project',
        customerEmail: 'Customer Email',
        rating: 'Rating',
        feedback: 'Feedback',
        status: 'Status',
      },
    },
    tasklist: {
      buildingHeader: 'Building your checklist...',
      buildingMessage:
        'This should be done in a minute or two. You can safely navigate away from this screen and check back later.',
      hideCompletedTasks: 'Hide Completed',
      toggle: {
        accessibilityLabel: 'Hide Completed',
      },
      restrictedPermissionNotice:
        'Editing this Checklist is restricted. Contact your admin to make changes.',
      onboardPermissionsTitle: 'Changes to User Permissions within Checklists',
      onboardPermissionsMessage:
        'Users with Standard and Restricted permissions can no longer edit Checklists created by another user. Users with these permissions can still view, complete, and create their own Checklists.\n\n Likewise, Collaborators will no longer be able to edit Checklists, but will still be able to view, complete, and create their own Checklists.',
      onboardSkipSectionsTitle: 'Mark Sections as Not Applicable',
      onboardSkipSectionsMessage:
        'In the mobile app, users can now mark Checklist sections as N/A if not relevant to the job at hand. \n\n To use this setting, go to the Checklist Template editor and toggle required sections off.',
    },
    taskListTemplate: {
      error: 'Something went wrong while loading your checklist template.',
    },
    trashCan: {
      daysToPurgeCount: '%{count} Days',
      screenreaderPurgeCount: 'This item will be deleted in %{count} days',
      screenReaderPurgeToday: 'This item will be deleted today',
      purgeToday: 'Today',
      videoIconTitle: 'This is a video',
      emptyHeading: 'Nothing to See Here',
      emptyMessage: 'No photos are currently in the Trash.',
      errorMessage: 'There was an error loading the Trash. Probably our fault!',
      restoreAssetsLoading: 'Moving Photos back to project...',
      selectAll: 'Select All',
      changePreviewSize: 'Change photo preview size',
      moveBackToProject: 'Move Back to Project',
      restoreSuccessMessage: {
        one: 'Review %{count} Photo',
        other: 'Review %{count} Photos',
      },
      restoreFailureMessage:
        'There was an error. Your photos have not been restored',
      introMessage:
        "Items will be deleted 90 days after they're moved to Project Trash.",
      documentsComingSoon:
        "We're working on getting your Documents in and out of the Trash.",
      reportsComingSoon:
        "We're working on getting your Reports in and out of the Trash.",
      title: 'Project Trash',
    },
  },
  filtersModal: {
    userSelect: {
      disabledMessage: 'Disabled for My Projects view',
    },
  },
  shared: {
    restrictedAccessBanner: {
      freePremiumTrialLink: 'Try Premium for Free',
      action: 'Contact your company admin to upgrade your plan.',
      upgradePlanLink: 'Upgrade Plan',
    },
    dropDown: {
      singleDropDown: {
        idleText: 'No Results',
      },
    },
    favoritedStar: {
      favoritedStar: {
        unStarItem: 'Unstar Item',
        starItem: 'Star Item',
      },
    },
    header: {
      userMenuItem: {
        integrations: 'Integrations',
      },
    },
    messageModal: {
      defaultConfirmLabel: 'Okay',
    },
    userGroupSearchSelect: {
      placeholder: 'Search Users and Groups',
      idleText: 'Type to search',
    },
  },
  projectTask: {
    deleteModal: {
      confirm: 'Are you sure you want to delete this task?',
      title: 'Delete Task',
    },
    assignUserErroMsg: 'There was an error while assigning a user to the task',
  },
  customers: {
    index: {
      backToProjectLabel: 'Back to Project',
      customersHeaderTitle: 'Send invites to Trusty, our customer portal app.',
      photosHeaderTitle: 'Manage what photos can be seen in Trusty.',
      trustyUsers: 'Trusty Users',
    },
    invite: {
      get: {
        error: 'We were unable to fetch the invite link.',
      },
      post: {
        error:
          'Something went wrong while creating the Trusty invitation link.',
      },
      revokeAccess: {
        success: 'Customer access has been revoked.',
        failed: 'We could not revoke customer access at this time.',
        title: 'Revoke Customer Access',
        info: 'Are you sure you want to revoke customer access to this project? You will need to create and share a new link if you change your mind.',
        confirmLabel: 'Yes, Revoke',
      },
    },
    trustyMedia: {
      emptyTitle: 'Keep Track of Shared Photos',
      emptyText: 'Every photo and video visible in Trusty will be shown here.',
      errorText: "We couldn't load the shared media for this project.",
      emptyImageAltText:
        'A set of photo thumbnails representing photos in Trusty',
      skeletonLoaderAltText: 'Loading shared project media',
    },
    photos: {
      sharePhotos: 'Share Photos',
      selectPhotos: 'Select Photos',
      allPhotos: 'All Project Photos',
      confirmLabel: 'Share All',
      confirmationMessage:
        'All existing photos and videos in this project will be visible in the Trusty app.',
      confirmationTitle: 'Share all Project Photos?',
      shareAllSuccess: 'Sharing project photos. This could take a few minutes.',
      shareAllError: 'Failed to share all photos. Please try again.',
    },
    restrictedMedia: {
      selectToShare: 'Select project photos to share.',
      loadingA11y: 'Loading restricted project media',
      errorMessage: "We couldn't load the media for this project.",
      emptyTitle: "There's Nothing Left to Share",
      emptyMessage: 'Customers can see all photos and videos for this project.',
      emptyAlt: 'Graphic of two hands high-fiving',
      errorToastMessage: 'Failed to share photos. Please try again.',
      successToastMessage: {
        one: 'Review %{count} photo',
        other: 'Review %{count} photos',
      },
    },
    selectedMediaActionBar: {
      confirmTitle: 'Hide Photo?',
      pluralConfirmTitle: 'Hide Photos?',
      confirmMessage:
        'Once a photo has been hidden, it is no longer visible in the Trusty app.',
      errorToastMessage: 'Failed to hide photos. Please try again.',
      photosHiddenToastMessage: 'photos hidden.',
      photoHiddenToastMessage: 'photo hidden.',
    },
    hypeModal: {
      title: 'An easier way to coordinate with your customers.',
      description:
        'Invite customers to use Trusty, the new customer portal from CompanyCam, where they can:',
      checklist: {
        one: 'Take and upload photos that sync with CompanyCam',
        two: 'Pay with credit card, ACH, Apple Pay or Google Pay',
        three: 'Stay up-to-date with project notifications',
      },
      logoAltText: 'Trusty logo',
      imageAltText: 'Trusty app on a phone',
    },
    tabs: {
      disabledTooltip: 'Create Trusty invite link first.',
    },
    trustyUsersList: {
      emptyTitle: 'Manage Customer Access',
      emptyText: 'Customers that have Trusty access will be shown here.',
      errorText: "We couldn't load the customers for this project.",
      emptyImageAltText:
        'Trusty logo with payment and photo thumbnail graphics',
      nameColumnTitle: 'Name',
      infoColumnTitle: 'Info',
      statusColumnTitle: 'Account Status',
      skeletonLoaderAltText: 'Loading customers',
    },
    projectInvitationLink: {
      create: 'Create Trusty Invite Link',
    },
    optionsMenu: {
      accessibilityLabel: 'Open Project Invitation Options Menu',
    },
  },
  trusty: {
    valuePropsModalNew: {
      title: 'Customer Portal',
      description:
        'Give access to Trusty, the new customer portal from CompanyCam, and coordinate projects like never before.',
      valueProp1: 'Receive customer photos in CompanyCam',
      valueProp2: 'Have customers pay you directly from Trusty',
      valueProp3: 'Get notifications for customer activity',
    },
  },
  qBO: {
    modal: {
      send: 'Send',
      selectRecord:
        'Select the invoice, estimate, or customer record you want to send these photos to:',
      selectPlaceholder: 'Select an item',
      noCustomerRecord:
        "Looks like you don't have a corresponding Quickbooks customer record for this project, so a new record will be created for you.",
      noInvoicesOrEstimates:
        "Looks like you don't have any invoices or estimates for the corresponding Quickbooks customer. You can still send photos to the customer record though.",
      customerRecord: 'Customer Record',
      invoice: 'Invoice',
      estimate: 'Estimate',
    },
  },
  exportTaskList: {
    exportPdfToastTitle: "We'll notify you when your PDF is ready.",
    exportPdfToastMessage:
      "We're building your PDF - when it's finished, we'll send you a notification and you can download it from there.",
  },
  taskList: {
    templates: {
      partners: 'There was an error loading your template partners.',
    },
  },
  taskLists: {
    emptyTitle: "Don't Drop the Ball",
    emptyMessage: 'Track what needs to get done and who needs to do it.',
    altText:
      'Image of a checklist with progress indicator and photos included.',
  },
  useTemplateModal: {
    previewButtonLabel: 'Preview Template',
    editButtonLabel: 'Edit Template',
    dropdownArrowAccessibilityLabel: 'Dropdown arrow',
    defaultTitle: 'Choose Template',
    searchPlaceholder: 'Search for a Template',
    deleteModal: {
      title: 'Delete Page Template',
      body: 'Are you sure you want to delete this page template? You cannot undo this action.',
    },
    getTemplatesErrorMessage:
      'Something went wrong while loading your templates',
  },
  referral: {
    modal: {
      title: 'Get $100 for Referrals',
      description:
        "Share your unique referral link via text, email, or whatever is easiest. You'll get $100 if your referral becomes a paying customer for 2 months.",
      programDetails: 'See Program Details',
      creatingLink: 'Creating Custom Link',
      linkError: 'There was an error loading your custom referral link.',
    },
  },
  reports: {
    getReports: {
      fetchError: 'There was a problem fetching the reports.',
    },
    createForm: {
      title: 'Report Title',
      createButton: 'Create Report',
    },
    create: {
      modalTitle: 'Create New Report',
      titleRequired: 'Report title required',
      layoutRequired: 'Please select a photo page layout',
      error: 'There was a problem creating your report',
    },
    entries: {
      createError: 'There was a problem adding item to report',
      loadPhotoDescription: 'Load Photo Description',
      loadPhotoComments: 'Load Photo Comments',
      deleteEntry: 'Delete Entry',
      deleteSuccess: 'Entry deleted',
      deleteError: 'There was a problem deleting the entry.',
      deleteConfirm:
        'Are you sure you want to delete this photo? This action cannot be undone.',
    },
    sections: {
      createError: 'There was a problem creating the section',
    },
    templateEditModal: {
      errorHeadline: 'Well, Shoot.',
      deleteSectionConfirmation:
        'Are you sure you want to delete this section?',
    },
    templateListModal: {
      errorMessage: 'We hit an error loading your templates.',
      spinnerMessage: 'Loading Templates...',
      noTemplatesHeadline: 'No Templates Found',
      noTemplatesMessage: 'Please save a report as a template first.',
      useTemplateHint: 'Click to use template',
      header: 'Use a Template to Create a Report',
    },
    templateDescription: {
      label: 'Template Description',
      placeholder: 'Enter Template Description',
    },
    getReport: {
      fetchError: 'There was a problem fetching the report.',
    },
    fetch: {
      error: 'There was a problem fetching reports.',
    },
    delete: {
      error: 'There was a problem deleting the report.',
      confirm: 'Are you sure you want to delete this report?',
    },
    readOnly:
      'Reports are read-only on your current plan. Upgrade your plan to use reports.',
    emptyTitle: 'Wanna Look Real Professional?',
    emptyMessage: 'Create and share PDF photo reports in just minutes.',
    altText: 'Image of a set of photo reports.',
    placeholderText: 'Find a report...',
    noReportsFound: 'No reports found',
    title: 'Reports',
    loadMore: 'Load More',
    messages: {
      deleteSectionModalTitle: 'Delete Section',
    },
    section: {
      titleInputLabel: 'Section Title',
      createSuccess: 'Section created',
      createError: 'There was a problem creating the section.',
      updateSuccess: 'Section updated',
      updateError: 'There was a problem updating the section.',
      noSections: "Looks like you haven't created any Sections yet.",
      cta: "We've improved the way you're able to organize and\n                        present reports. Get started by creating a section and\n                        adding your photos into it!",
      deleteSuccess: 'Section deleted',
      deleteError: 'There was a problem deleting the section.',
      deleteConfirm:
        'Are you sure you want to delete this section? All section content will be permanently removed from this report. This action cannot be undone.',
      rearrangePhotos: 'Rearrange Photos',
      movePhotos: 'Move Multiple Photos',
      loadPhotoDescriptions: 'Load Photo Descriptions',
      loadComments: 'Load Photo Comments',
      deleteSection: 'Delete Section',
      emptySummary: 'Click to add important info or findings.',
      emptySummaryCTA: 'Section Summary',
      summaryWarning:
        "Section summaries are currently hidden. To enable them, go to this report's Settings and turn on Section Title Pages.",
      photoOptions: 'Photo Options',
      addPhotos: 'Add Photos',
      newSection: 'New Section',
    },
    shareReportLabel: 'Share Report',
    shareReportModal: {
      errors: {
        getLink: 'There was an issue getting your shareable link.',
        generateLink: 'There was an issue generating a new link.',
      },
      title: 'Share Your Report',
      generateNewLink: 'Generate New Link',
      securityMessage: 'This link will expire',
      securityExpiredMessage: 'This link expired',
    },
    editing: {
      fetchError: 'There was a problem fetching the report.',
      createFirstSection: 'Create First Section',
      tooltipMsg: 'Save as a template to quickly build future reports',
      tooltipUpgradeMsg:
        'Upgrade to save as template to quickly build future reports',
      manage: 'Manage',
      settings: 'Settings',
      viewReport: 'View Report',
      saveAsTemplate: 'Save as Template',
      deleteReport: 'Delete Report',
    },
    generatePdf: 'Generate PDF',
    collab: {
      alertStrong: 'Reports are not shared between collaborators.',
      alert: 'Reports you add are only seen by %{companyName}.',
    },
    rearrange: {
      label: {
        selectPhotos: 'Rearrange Photos',
        selectSection: 'Select Section',
        confirmSelection: 'Confirm and Add Photos',
      },
      saveButton: 'Save',
      sectionNoContentTitle: 'This section needs some content!',
      sectionNoContent:
        'Click “Confirm and Add Photos“ to show it a little love. Your photos will be moved to this section in the order they were selected.',
    },
    settings: {
      settingsHeading: 'Report Settings',
      coverUpdated: 'Cover photo updated.',
      coverError: 'There was a problem updating cover photo.',
      descriptionsLoaded: 'Imported photo descriptions.',
      descriptionsError: 'There was a problem importing photo descriptions.',
      commentsLoaded: 'Imported photo comments.',
      commentsError: 'There was a problem importing photo comments.',
      visible: 'Visible',
      hidden: 'Hidden',
      reportDate: 'Report Date',
      photoPageLayout: 'Photo Page Layout',
      photoPageHeader: 'Photo Page Header',
      showHide: 'Show/Hide',
      title: 'Report Title',
      subtitle: 'Report Subtitle',
      labelLeft: 'Left',
      labelCenter: 'Center',
      labelRight: 'Right',
      headerExplanation:
        'This information will be displayed in the header of each photo page when you create the PDF report.',
      charLimit: 'Character limit',
      toggleSummaryTitle: 'Section Title Pages',
      toggleSummaryDesc: 'Show title and summary on full page',
      toggleSummaryWarning:
        'Just FYI: Turning this setting off means that section summaries will\n                be hidden.',
      photoNumbersLabel: 'Photo Numbers',
      photoNumbersDesc: 'Numbers will display the photo order',
      whoLabel: 'Photo Captured By',
      whoDesc: 'Show the user who took the photo',
      whereLabel: 'Location Captured',
      whereDesc: 'Project the photo was captured',
      dateLabel: 'Date Captured',
      dateDesc: 'Time and date the photo was captured',
      tagsLabel: 'Photo Tags',
      tagsDesc: 'Show a list of the photo tags',
    },
    reportSelectModal: {
      addPhotostoReport: 'Add Photos to Report',
      selectReport: 'Select a report',
      addtoReport: 'Add to Report',
    },
    addEntryMenu: {
      photo: 'Insert a Photo',
    },
    photoEntry: {
      setCoverPhoto: 'Set as Cover Photo',
      coverPhotoLabel: 'Cover Photo',
      editPhotoTooltip: 'Click to Edit Photo',
    },
    noteEditor: {
      clickToAddTextSnippets: 'Click here to add text',
    },
    createReport: 'Create Report',
    newReport: 'New Report',
    useExistingTemplate: 'Use Existing Template',
    coverPage: {
      featuredImgAlt: 'Featured item to display on cover page',
      coverPhotoLabel: 'Cover Photo',
      coverPhotoSubtitle: 'Display the cover photo, if you selected one',
      logoImgAlt: 'Company logo to display on the cover page',
      companyLogoTitle: 'Company Logo',
      companyLogoSubtitle:
        'Display your logo, if your company uploaded one to CompanyCam',
      companyNameTitle: 'Company Name',
      companyNameSubtitle: 'Display your company name',
      creatorNameTitle: 'Creator Name',
      creatorNameSubtitle:
        'Display the name of the person who created the report',
      photoCountTitle: 'Photo Count',
      photoCountSubtitle: 'Display the number of photos in the report',
      dateCreatedTitle: 'Date Created',
      dateCreatedSubtitle: 'Display the date the report was created',
    },
    templates: {
      onboardModalHeader: 'The Template editor is moving!',
      onboardModalSubheader:
        'Looking to edit a Report template? Check out our new all-in-one templates manager for reports, checklists, and projects. You can find it by clicking on your avatar in the sidebar and then clicking on the Templates menu item.',
      onboardModalAction: 'Check out Templates',
      onboardModalDismiss: 'Dismiss',
    },
    templateSaveModal: {
      title: 'Save as Report Template',
      templateNameLabel: 'Template Name',
      templateNamePlaceholder: 'e.g., Inspection Template',
      templateDescriptionLabel: 'Template Description (optional)',
      titleRequired: 'Template name required',
      titleLength: 'Template name must be less than 100 characters',
      error:
        'Shoot. There was an error creating your template. Probably our fault! Please refresh the page and try again.',
      successTitle: 'Template Saved',
      successMessage:
        '%{title} has been saved as a template you can use when you create a new report.',
      successButtonLabel: 'OK, Got It',
    },
    templateValues: {
      description:
        'Your new template will include the following from %{reportTitle}:',
      pageLayout: 'Page layout',
      reportSettings: 'Report settings',
      coverPageSettings: 'Cover page settings',
      sectionAndOrder: 'Section names, summaries and order',
    },
    reportSelect: {
      selectReport: 'Select a report',
    },
  },
  reportTemplate: {
    section: {
      titleInputLabel: 'Section Title',
      summaryLabel: 'Section Summary',
    },
  },
  resources: {
    partnerStatus: {
      subscribed: 'Subscribed',
      requested: 'Requested',
      restricted: 'Restricted',
    },
  },
  roles: {
    fitFor: 'A great fit for:',
    adminDescription:
      'Complete control. This is the only level that can upgrade or cancel the account.',
    adminModalDescription:
      'These users have your full trust and complete access to everything. They can manage users and set up integrations. Admins can create or delete anything, as well as upgrade, downgrade, or cancel your account.',
    adminFitFor1: 'President or owner',
    adminFitFor2: 'Controller',
    adminFitFor3: 'Office admin',
    managerDescription:
      'Can access all projects/features, manage users, and delete. Cannot manage billing.',
    managerModalDescription:
      'These users have access to all projects and features. They can manage users and set up integrations. Managers can create or delete anything, but cannot upgrade, downgrade, or cancel your account.',
    managerFitFor1: 'Crew lead',
    managerFitFor2: 'Foreman',
    managerFitFor3: 'Supervisor',
    standardDescription:
      'Can access all projects/features. Cannot delete or manage billing/users.',
    standardModalDescription:
      'These users can view all of your projects, manage collaborations, and use all features. They cannot manage users, delete anything, or upgrade, downgrade, or cancel your account.',
    standardFitFor1: 'Crew member',
    standardFitFor2: 'Full-time employee',
    standardFitFor3: 'Technician',
    restrictedDescription:
      'Can only access projects they create or are assigned to.',
    restrictedModalDescription:
      'These users cannot access all company projects. Restricted users can only view projects they create or that you assign to them. Within those projects, they can view content, add content, and access reports and checklists. They cannot manage users or delete anything.',
    restritedFitFor1: 'Sub contractor',
    restrictedFitFor2: 'Part-time employee',
    restrictedFitFor3: 'Employees on a "need-to-know" basis',
  },
  search: {
    title: 'Search',
    input: {
      placeholder: 'Search for...?',
    },
    result: {
      empty: {
        state:
          'Search for Projects, Users, Comments, Tags, Reports, Project Contacts, and more.',
      },
      group: {
        groupBadge: 'Group',
      },
    },
    results: {
      loading: 'Loading Results',
    },
    paginationbar: {
      previouspage: 'Previous page',
      nextpage: 'Next page',
    },
    no: {
      results: 'Try searching different keywords',
      title: 'No Results Found',
    },
    view: {
      all: 'View All',
    },
  },
  assignUsers: {
    unassignButtonLabel: 'Unassign',
    emptyStateMessage: 'Assign to one or multiple users',
    searchInputPlaceholder: 'Search for Users',
    searchInProgressMessage: 'Finding your people',
    restrictedUserConfirmationModal: {
      title: 'Assign Restricted User?',
      confirmButton: 'Yes, Assign',
      message:
        'Assigning this user gives them access to the project. They will be able to view Photos, add Photos, join Conversations, and access Reports and Checklists.',
      subtitle: '%{name} is a Restricted user',
    },
  },
  assignUser: {
    searchErrorMessage: 'Error loading users',
    searchNoResultsMessage: 'No users found.',
  },
  dropdown: {
    trigger: {
      defaultAriaLabel: 'Open Options Menu',
    },
  },
  modal: {
    toast: {
      successMessage: 'Changes Saved!',
      errorMessage: 'Error Saving',
    },
  },
  inputClipboard: {
    copyError: 'Could not copy this text.',
    copySuccess: 'Link Copied to Clipboard',
    copy: 'Copy',
    retry: 'Retry',
  },
  upgradeBadge: {
    label: 'Upgrade for Access',
  },
  floatingActionBar: {
    closeButtonAltText: 'close button',
  },
  freeLimitsAvailable: {
    remainingFreeUsers:
      '%{remainingCount} free {remainingCount, plural, one %{user} other %{users}} remaining',
    remainingFreeProjects:
      '%{remainingCount} free {remainingCount, plural, one %{project} other %{projects}} remaining',
    upgrade: 'Upgrade',
    contactAdmin: 'Contact your company admin to upgrade your plan.',
  },
  todosMarketplace: {
    index: {
      restrictedAccessBanner:
        "Your current plan doesn't include access to %{featureName}.",
      restrictedAccessBannerMsg:
        "Your current plan doesn't include access to %{featureName}.",
      title: 'Community Checklists',
      description:
        'Lists designed by industry leaders to make your life easier.',
      emptyPartnersMsg: 'No Partners Found',
      onboardModalBtnLabel: "Let's Go",
      onboardModalDescription:
        'Add manufacturer, franchise, or general trade checklist templates to your account. Anyone at your company can use them when creating new checklists in Projects.',
    },
    partner: {
      subscribedSuccessMsg: {
        one: 'Review %{count} Template',
        other: 'Review %{count} Templates',
      },
      unsubscribedSuccessMsg: {
        one: 'Review %{count} Template',
        other: 'Review %{count} Templates',
      },
      subscribedErrorMsg:
        'Could not subscribe to the partner. Please try again.',
      unsubscribedErrorMsg:
        'Could not unsubscribe from the partner. Please try again.',
      requestErrorMsg: 'Could not request access. Please try again.',
      addTemplateSuccessMsg: 'Template Added to Library',
      addTemplateDeleteMsg: 'Template Removed from Library',
      addTemplateErrorMsg:
        'Could not add template to library. Please try again',
      deleteTemplateErrorMsg:
        'Could not delete template to library. Please try again',
      subscribe: 'Subscribe',
      unsubscribe: 'Unsubscribe',
      unsubscribeModalTitle: 'Are you sure?',
      unsubscribeModalMessage:
        'Unsubscribing will remove these templates from your template library.',
      requestAccess: 'Request Access',
      accessRequested: 'Access Requested',
      requestModalMessage:
        'You will be notified via email when this partner has responded. If granted access, this partner’s templates will be available under the <strong>Use Existing Templates</strong> option in a project’s <strong>Create To-Do List</strong> menu.',
      requestModalConfirmBtn: 'Got it',
      subscribedModalTitle: 'All Templates Added',
      subscribedModalMsg:
        'You can now use every checklist template from this partner. Newly created templates will automatically be added to your library as well.',
      onboardToolTipMsg:
        'Automatically add new and existing templates to your library.',
      restrictedToolTipMsg: 'Permission is required to access templates.',
      added: 'Added',
      notAdded: 'Not Added',
      detailsColHeader: 'Template Details',
      addedColHeader: 'Added to Library',
      addTemplateButton: 'Add',
      removeTemplateButton: 'Remove',
      removeTemplateTooltip: 'Unsubscribe to add or remove individually.',
      addedTemplateModalTitle: 'Template Added',
      addedTemplateModalMsg:
        'You can now use this template when creating a checklist from <strong>an Existing Template</strong>.',
      cardTooltip: 'Click on a partner to explore their templates',
    },
  },
  richTextEditor: {
    editor: {
      saveSelectedTextSnippet: 'Create new text snippet from selected',
      saveTextSnippet: 'Create new text snippet with content',
    },
    toolbar: {
      snippets: 'Text Snippets',
      addSnippet: 'Add Text Snippet',
      link: 'Link',
      unlink: 'Unlink',
    },
  },
  snippets: {
    savedSnippets: {
      searchPlaceholder: 'Find a text snippet...',
      createBtn: 'Create New',
    },
    snippetsList: {
      emptyMsg: 'Bummer! No text snippets.',
      createBtn: 'Create one!',
      useBtn: 'Use',
      newSnippetTitle: 'Text Snippet Title',
      newSnippetInfo: '(Your saved text snippet will appear here)',
    },
    form: {
      title: 'Title',
      errorMsg:
        "Hmmm. There was a problem. Make sure you've added a title and a text snippet",
    },
    snippetEditor: {
      snippetsDetailMsg: 'Click on a snippet to view more details.',
      snippetsInfoHeader: 'You just got some time back!',
      snippetsInfoContent:
        'No more copy/paste or re-typing text over and over. Text snippets let you save reusable blocks of text.',
    },
    modal: {
      title: 'Text Snippets',
    },
  },
  snippetEditor: {
    create: {
      createSuccessMsg: 'Snippet Successfully Saved',
      createErrorMsg: 'Could Not Create Snippet. Please try again',
      header: 'New Snippet',
    },
    delete: {
      deleteSuccessMsg: 'Snippet Successfully Deleted',
      deleteErrorMsg: 'Could Not Delete Snippet. Please try again',
      header: 'Edit Snippet',
    },
    edit: {
      editSuccessMsg: 'Snippet Saved',
      editErrorMsg: 'Could Not Edit Snippet. Please try again',
      useSnippetBtn: 'Use Snippet',
      header: 'Edit Snippet',
    },
  },
  showcase: {
    publishDropdown: {
      createShowcase: 'Showcase This Project',
      editShowcase: 'Edit Showcase',
    },
    list: {
      getShowcaseProject: {
        error: 'Something went wrong when loading this showcase.',
      },
      installShowcaseWidget: 'Install Showcase Widget',
      title: 'Showcases',
      query: {
        error:
          "Sorry, something went wrong and the showcase list can't be loaded right now. Please try again later.",
      },
      column: {
        showcaseName: 'Showcase Title',
        createdAt: 'Date Created',
        projectName: 'Project',
        status: 'Enabled',
      },
      setupGuide: 'Setup Guide',
      emptyStateHeadline: 'Showing work on your website just got easier.',
      emptyStateSubhead:
        'Your potential customers love looking at photos of your past jobs, but you don’t have hours to spend updating your site. With showcases, it’s simple to publish your CompanyCam photos directly to your website.',
      emptyStateButtonPrimary: 'Learn How',
    },
    create: {
      v2PublishWizard: {
        main: {
          description1: 'Check out our ',
          description2: 'setup guide ',
          description3:
            'to learn more about creating and posting showcases to your website.',
        },
      },
    },
    wizard: {
      media: {
        error: 'An error occurred while loading the photos. Please try again.',
      },
      selectPhotos: {
        headerTitle: 'Choose Photos to Share',
        headerDescription:
          'Select any photos you want to share in your published showcase.',
      },
    },
  },
  showcases: {
    publishDropdown: {
      deleteShowcaseModal: {
        success: 'Successfully Deleted!',
        error: 'Whoops! We failed to delete this showcase',
      },
      visibilityToggle: {
        error: {
          publish: "We couldn't enable this showcase at this time.",
          unpublish: "We couldn't disable this showcase at this time.",
        },
      },
      main: {
        deleteConfirmButton: 'Delete Showcase',
      },
    },
    list: {
      pagination: {
        rowLimitLabel: 'Rows Per Page',
      },
    },
    setupGuide: {
      nullShowcase: {
        instructions:
          'You haven\'t posted any projects yet. Choose a project you want to post and go through the "Showcase This Project" process.',
      },
      heading: 'Install Showcase Widget',
      stepLabel: 'Step %{number}',
      buttonLabel: 'Copy Code Snippet',
      script: {
        instructions:
          "Copy and paste this code snippet inside your site's <body>. We recommend placing it just before the closing </body> tag.",
      },
      root: {
        instructions:
          "Copy and paste this code snippet where you want the showcase to display on your site. You can show it anywhere in the <body> as long as it's placed above the code snippet in Step 1.",
      },
      help: {
        instructions1: 'Need more help? Check out our ',
        instructions2: 'setup guide ',
        instructions3:
          'that includes a video walkthrough and more detailed instructions for installing it on the most popular website builders.',
      },
    },
    create: {
      publishWizard: {
        closeConfirm: {
          heading: 'Are you sure you want to exit?',
          description:
            'Any changes you have made to the Showcase will not be saved.',
          confirmButton: 'Yes, exit',
          cancelButton: "No, don't exit",
        },
        deleteConfirm: {
          heading: 'Delete Showcase',
          description:
            'Are you sure you want to delete this Showcase from your website? You will have to start from scratch if you want to create this Showcase again.',
          confirmButton: 'Yes, delete',
          cancelButton: "No, don't delete",
        },
        main: {
          materials: {
            emptyState: 'No materials found',
          },
          tags: {
            error: 'Something went wrong. Please try again.',
          },
          types: {
            emptyState: 'No types found',
          },
        },
        tags: {
          select: 'Select %{tagType}',
          createMessage: 'Create %{inputValue}',
        },
      },
      v2PublishWizard: {
        coverPhoto: {
          title: 'Choose a Cover Photo',
          description:
            'This will be shown on the preview cards as well as the main header area of the individual showcase page.',
        },
        noTitleError: 'Give your showcase a title to continue.',
        minTypesError: 'You must select at least one project type to continue.',
        minProductsError:
          'You must select at least one project product to continue.',
        main: {
          descriptionLabel: 'Description/Summary (Optional)',
          productsUsedLabel: 'Products Used',
          title: {
            create: 'New Showcase',
            edit: 'Editing Showcase',
          },
          suggestedTitleButton: 'Use This Title',
          suggestedTitleLabel: 'Suggested Title:',
          typeLabel: 'Project Type',
          visbilityLabel: 'Enabled',
          coverPhotoButton: 'Change Cover Photo',
          types: {
            label: 'Project Type',
          },
          products: {
            label: 'Products Used',
          },
        },
        minAssetsError: 'Select at least one photo.',
        minCoverPhotoError: 'Select a cover photo.',
        showcaseCreated: 'Showcase created successfully.',
        showcaseFailed: 'Failed to create showcase.',
      },
    },
    v2PublishWizard: {
      editShowcase: {
        success: 'Successfully Updated!',
        error: 'Whoops! We failed to update this showcase',
      },
    },
    popover: {
      publish: 'Publish',
      trusty: {
        heading: 'Publish Showcases to Trusty',
        description:
          'Be one of the first to share work and get leads from Trusty.',
        edit: 'Edit Profile',
        view: 'View Trusty Profile',
        setup: 'Setup Trusty Profile',
        alt: 'Publish to Trusty',
      },
      web: {
        heading: 'Publish Showcases to My Website',
        description: 'Keep your website up-to-date with your best work.',
        install: 'Install Website Widget',
        alt: 'Publish to Website',
      },
    },
  },
  companySize: {
    select: 'Select one...',
    label: 'Company Size',
  },
  heard: {
    select: 'Select one...',
    default: 'How did you hear about us?',
    podcast: 'Podcast',
    detail: {
      which: 'Which one?',
      social: 'Which platform?',
      someone: 'Who was it? We have gifts.',
      tradeShow: 'What was it called?',
      manufacturer: 'Can you tell us more?',
      other: 'Can you tell us more?',
    },
    softwareIntegrationPartner: 'Software Integration Partner',
    socialMedia: 'Social Media or Search',
    someone: 'Someone told me',
    tradeShow: 'Trade Show or Event',
    manufacturer: 'Manufacturer or Industry Group',
    title: 'How did you hear about us?',
  },
  primaryUserTitle: {
    select: 'Select one...',
    label: 'Role',
    projectManagement: 'Project Management',
    sales: 'Sales',
    fieldTechInstallation: 'Field Tech/Installation',
    operations: 'Operations',
    officeAdministration: 'Office Administration',
    owner: 'Owner',
    other: 'Other',
  },
  companyDocument: {
    displayError: "We aren't able to display this document",
    errorDescription:
      "This document isn't supported by our previewer, but you can download it by clicking the button below.",
    delete: {
      title: 'Delete Project Document',
      confirmationMsg:
        'Are you sure you want to delete this Project Document? It will be removed if used on a Project Template, but not from any existing projects',
    },
  },
  companyDocuments: {
    upload: {
      addDocuments: 'Add Documents',
      maxFileSize: 'Maximum file size of 100MB.',
      maxFileSizeSignatures:
        "If you'd like to get this document signed, upload as a .pdf or .docx file under 40MB and less than 500 pages.",
      dragAndDropMessage:
        'Drag & Drop document here or click to upload to this project.',
      uploadMessaging: 'Uploading %{count} documents…',
      errorMessage: 'Could not upload %{fileName}',
      errorMessagePlural:
        'Could not upload %{fileName} and %{count} other file(s)',
      successMessage: 'The file was successfully uploaded',
      successMessagePlural: 'The files were successfully uploaded',
    },
    delete: {
      successMessage: 'Document Deleted',
      errorMessage: 'There was an error deleting this document',
    },
    updateSuccessMessage: 'Document name updated',
    updateErrorMessage: 'Could not update the document name. Please try again',
  },
  pageTemplates: {
    preview: {
      editTemplate: 'Edit Template',
      createFromTemplate: 'Create from Template',
      createFromTemplateSuccess: 'Page created successfully',
    },
  },
  templates: {
    shared: {
      taskListsTab: 'Checklists',
      reportsTab: 'Reports',
      projectsTab: 'Projects',
      documentsTab: 'Documents',
      pagesTab: 'Pages',
      templatesHeader: 'Templates',
      templatesSubheader: 'Create and manage templates across your company.',
      templateNameHeader: 'Template Name',
      lastUpdatedHeader: 'Last Updated',
      onboardModalDescription:
        'Create, edit, and save your Checklist and Report templates in one spot, all without having to go into a project.',
      onboardModalBtnLabel: "Let's Go",
      createChecklistTemplate: 'Create Checklist Template',
      editingReportTemplateDisclaimer:
        'You’re currently editing a template. Any changes you make will not affect previously created reports.',
      createReport: 'Create Report Template',
      createProject: 'Create Project Template',
      templateTitlePlaceholder: 'Enter Template Title',
      templateDescriptionPlaceholder: 'Enter Template Description',
      checklists: 'Checklists',
      reports: 'Reports',
      companyDocuments: 'Project Documents',
      pages: 'Pages',
      addChecklists: 'Add Checklists',
      multiSelectModal: {
        empty: 'No Templates Found',
      },
      addReports: 'Add Reports',
      checklistModalHeader: 'Add Checklists',
      reportModalHeader: 'Add Reports',
      projectTemplateTaskListEmptyState:
        "You've added all of your available checklist templates to this project template.",
      projectTemplateReportEmptyState:
        "You've added all of your available report templates to this project template.",
      projectTemplateCompanyDocumentEmptyState:
        "You've added all of your available project documents to this project template.",
      deleteProjectTemplate: {
        title: 'Delete Template',
        message:
          'Are you sure you want to delete this template? Deleting this project template will not delete the individual templates contained within it.',
      },
      deleteTemplateSuccess: 'The template was succesfully deleted',
      deleteTemplateError:
        'Something went wrong while deleting your template. Please try again.',
      setDefaultProjectTemplate: 'Set as Default Template',
      unsetDefaultProjectTemplate: 'Remove as Default Template',
      setDefaultProjectTemplateConfirm: 'Set default template',
      setDefaultProjectTemplateCancel: "No, don't set",
      unsetDefaultProjectTemplateConfirm: 'Remove default template',
      unsetDefaultProjectTemplateCancel: "No, don't remove",
      defaultProjectTemplateTooltip:
        'Set a default project template to automatically be pre-selected on any new project, including ones created through integrations.',
      setDefaultProjectTemplateTitle: 'Set Default Template',
      setDefaultProjectTemplateMessage:
        'Are you sure you want to use this as a default template? Any project created through an integration will automatically apply this template',
      succcessSetDefaultProjectTemplate: 'Successfully set default template',
      errorSetDefaultProjectTemplate:
        'There was an error setting this default template',
      unsetDefaultProjectTemplateTitle: 'Remove Default Template',
      unsetDefaultProjectTemplateMessage:
        'Are you sure you want to remove this as a default template? Projects created through integrations will not have a template automatically applied',
      succcesUnsetRemoveDefaultProjectTemplate:
        'Successfully removed default template',
      errorUnsetRemoveDefaultProjectTemplate:
        'There was an error removing this default template',
      defaultTemplateBadge: 'Default Template',
      addDocumentButton: 'Add Document',
      addsDocuments: 'Add Documents',
      documentFileTypeColumn: 'File Type',
      createPage: 'Create Page Template',
      projectTemplatePageEmptyState:
        "You've added all of your available page templates to this project template.",
      deletePageTitle: 'Delete Page Template',
      deletePageTemplateMsg:
        'Are you sure you want to delete this Page Template? This will not affect any pages you’ve already created with this template.',
      emptyPageTemplatesMsg: 'Streamline your projects with Page Templates',
      addPages: 'Add Pages',
      addLabels: 'Add Labels',
      managePageTemplates: 'Manage Page Templates',
      manageReportTemplates: 'Manage Report Templates',
      manageTaskListTemplates: 'Manage Checklist Templates',
      noRecentlyUsedTemplates: 'No Recently Used Templates',
    },
  },
  template: {
    shared: {
      createChecklistErrorMsg:
        'Something went wrong while creating a checklist template. Please try again.',
      emptyChecklistTemplatesMsg:
        'Streamline your projects with Checklist Templates',
      deleteTemplateButtonLabel: 'Delete',
      deleteChecklistTitle: 'Delete Checklist Template',
      deleteChecklistTemplateMsg:
        "Are you sure you want to delete this template? This won't affect any checklists you've already created with this template.",
      deleteReportTemplateMsg:
        "Are you sure you want to delete this template? This won't affect any reports you've already created with this template.",
      createReportErrorMsg:
        'Something went wrong while creating a report template. Please try again.',
      deleteReportTitle: 'Delete Report Template',
      emptyReportTemplatesMsg: 'Streamline your projects with Report Templates',
      editingReportTemplateError:
        'There was an error updating the report template',
      genericEditTemplateError: 'There was an error saving the template',
      templateCreateError:
        'Something went wrong while creating the page. Please try again.',
      templateDeletedSuccess: 'Template Deleted',
      templateDeletedError: 'There was an error deleting this template',
      templateCreatedError:
        'There was an error creating the template. Please try again.',
      emptyProjectTemplatesMsg:
        'Streamline your projects with Project Templates',
      addTemplates: 'Add to Project Template',
      manageDocuments: 'Manage Documents',
      clearSelection: 'Clear Selection',
      goToTemplates: 'Go to Templates',
      defaultEmptyStateMsg: 'Create a new template in the Templates page',
      projectTemplatesTooltip: 'Manage Project Templates Here',
      emptyCompanyDocumentMsg:
        'Streamline your projects with Project Documents',
    },
  },
  users: {
    users: {
      inviteSent: 'Invite created %{date}',
      roleTooltipMessage: 'Change user roles here!',
      toastHeadline:
        '{count, plural, one %{Invitation} other %{Invitations}} Sent!',
      toastBody: {
        one: 'Review %{count} user',
        other: 'Review %{count} users',
      },
      emptyState: {
        title: 'Collect photos from your team.',
        message:
          'Tip: Get work photos out of your team’s camera roll by inviting them to take pictures in CompanyCam.',
      },
      deactivated: 'Deactivated',
      noActivityMessage: 'No activity',
      invitedUser: 'Invited User',
      editNameAccessiblityLabel: 'Edit name',
      nameSavedSuccessMessage: 'Name saved successfully.',
      resendInvite: 'Resend Invite',
      editUser: 'Edit User',
      deactivate: 'Deactivate',
      reactivate: 'Reactivate',
      roles: {
        admin: {
          label: 'Admin',
          description: 'Has complete control over account.',
        },
        manager: {
          label: 'Manager',
          description: 'Access to all projects and can manage users.',
        },
        standard: {
          label: 'Standard',
          description: "Access to all projects but can't manage users.",
        },
        restricted: {
          label: 'Restricted',
          description:
            'Can only access projects they create or are assigned to.',
        },
      },
    },
    user: {
      inlineNameEdit: {
        firstNameTooLongError: 'First name is too long',
        lastNameTooLongError: 'Last name is too long',
        firstNameRequiredError: "First name can't be blank",
        lastNameRequiredError: "Last name can't be blank",
        save: 'Save name',
        cancel: 'Cancel editing name',
        firstNameLabel: 'First Name',
        lastNameLabel: 'Last Name',
      },
    },
    deactivateUserModal: {
      yesDeactivate: 'Yes, deactivate',
      noDontDeactivate: "No, don't deactivate",
      title: 'Deactivate User',
      confirmationMessage:
        'Are you sure you want to deactivate <strong>%{userName}</strong>?',
    },
  },
  user: {
    notificationSettings: {
      notifyByEmailLabel: 'As emails',
      notifyByEmailTooltip: 'Notifications will be sent to your inbox.',
      notifyByPushNotificationLabel: 'As push notifications',
      notifyByPushNotificationTooltip:
        'Notifications will push to your mobile device.',
      activityOnInteractedPhotoLabel: 'Responds to my comments',
      activityOnInteractedPhotoTooltip:
        "If a user interacts with any photo after you, we'll let you know.",
      photoReceivesActivityLabel: "Comments on photos I've taken",
      photoReceivesActivityTooltip:
        "If there's activity on a photo you created, we'll let you know.",
      unexpectedError: 'An unexpected error occurred',
      notificationsSettingsMessageSectionOne:
        "I'd like to receive notifications...",
      notificationsSettingsMessageSectionTwo:
        'Send me notifications when someone...',
    },
    notificationsSettingsModal: {
      notificationsSettings: 'Notification Settings',
    },
    profilePhoto: {
      changePhoto: 'Change Photo',
    },
    uploadProfilePhotoModal: {
      upload: 'Upload',
      uploading: 'Uploading…',
      modalInstructions:
        'Drag & Drop a photo here or click to upload a new profile photo.',
      previewYourPhoto: 'Preview Your Photo',
      uploadProfilePhoto: 'Upload Profile Photo',
    },
    index: {
      newInvitationButton: 'Invite Users',
    },
  },
  helpers: {
    strings: {
      nameEmpty: 'Name cannot be empty',
      invalidFilename: "Name should not start with a '.'",
    },
  },
  hooks: {
    useIgnorableConfirmation: {
      defaultIgnorableLabel: "Don't show this message again",
    },
  },
  yearInReview: {
    welcome: {
      header: {
        lineOne: 'Welcome to your',
        lineTwo: 'Year in Review',
      },
      description1: 'Here’s what ',
      description2: '%{companyName} ',
      description3: 'got up to in 2024.',
    },
    projectsCreated: {
      title: 'Projects\nCreated',
      positive_description1: 'You were ',
      positive_description2: 'booked and busy ',
      positive_description3: 'this year.',
      negative_description1: 'You do know you get unlimited storage? 😉',
    },
    photosTaken: {
      title: 'Photos Taken',
      positive_description1:
        'Your customers must sleep so easy at night with all that	',
      positive_description2: 'peace of mind.',
      negative_description1: "Pics or it didn't happen. So… did it?",
    },
    galleries: {
      title: 'Galleries\nMade',
      positive_description1: 'If ',
      positive_description2: 'photo sharing is caring, ',
      positive_description3: 'you really cared for your customers this year.',
      negative_description1:
        'You do great work—show it off with a branded gallery of project photos! Watch: ',
      negative_description_link: 'How to Share Photos with Galleries',
    },
    videosCaptured: {
      title: 'Videos\nCaptured',
      positive_description1: 'And the ',
      positive_description2: 'Academy Award ',
      positive_description3: 'goes to…',
      negative_description:
        'Eliminate the back-and-forth by showing and telling what’s happening on the job.',
    },
    pages: {
      title: 'Pages\nMade',
      positive_description1: 'Look at you—keeping your crews and customers ',
      positive_description2: 'on the same Page(s)!',
      negative_description1:
        'Get your notes out of your head and into an organized and sharable page. Read: ',
      negative_description_link: 'How to Create and Use Pages',
    },
    checklistsCreated: {
      title: 'Checklists Created',
      positive_description1: 'Getting things ',
      positive_description2: 'done right the first time ',
      positive_description3: 'is the key to your customers’ hearts.',
      negative_description1:
        'Checking things off feels so good. Give it a try. Watch: ',
      negative_description_link: 'How to Create a Checklist',
    },
    CoCamInfo: {
      newFeatureHeader: 'New to CompanyCam:',
      aiFeatureHeader: 'AI-Powered Tools',
      aiFeatureDescription:
        'This year, we launched powerful and effective actions that use AI magic to make your life easier. Create overview docs, get organized notes about your projects, or share a summary of your day—without having to type a single word. Now, we just need to figure out how to get AI to do your laundry, too.',
      aiFeatureLabel: 'Haven’t heard of AI actions?',
      aiFeatureCTA: 'Learn More',
      photoStatHeader: 'Photos Taken',
      photoStatDescription:
        "Holy smokes, that's a lot of photos taken in CompanyCam! Think of how many times those photos came in clutch to cover your butt—not to mention all the extra space left in camera rolls for pet photos. Talk about a win-win!",
      usersStatLabel: 'New Users Added',
      usersStatDescription:
        'Having the whole team on CompanyCam means everyone can find the info and project updates they need. That includes Barb in the office and Sam out making sales! Add your whole crew on your',
      usersStatDescriptionLink: ' billing page.',
      projectsStatLabel: 'Projects Created',
      projectsStatDescription:
        'Organization, communication, and productivity were at an all-time high this year. The number of projects created in CompanyCam was also record-setting. Coincidence? We think not.',
      projectsUpgradeStatLabelLink: 'Upgrade to Pro or Premium',
      projectsUpgradeStatLabelTwo: ' to get unlimited projects.',
      templatesStatLabel: 'Templates Created',
      templatesStatDescription:
        'The amount of time saved with Templates was enough to watch every second of the Paris Olympics—even the breakdancing.',
      templatesUpgradeStatLabelLink: 'Upgrade to Premium',
      templatesUpgradeStatLabelTwo: ' and try templates!',
      paymentsStatLabel: 'Payments Collected',
      paymentsStatDescription:
        'Finally, an easier way for contractors to get paid. Tell your customers to leave their checkbooks at home!',
      paymentsStatDescriptionLink: 'Try payments today!',
      integrationsStatLabel: 'Integrations Launched',
      integrationsStatDescription1:
        'We made so many new friends this year that we finally feel like the cool kid on the playground. Now you can ',
      integrationsStatLink: 'integrate ',
      integrationsStatDescription2:
        'CompanyCam with QuickBooks and AccuLynx, ServiceTitan is coming soon!',
      podcastStatLabel: 'Minutes of Yapping',
      podcastStatDescription:
        'We launched our Good Contractor Podcast this year and we’ve been honored by the incredible guests who have joined to talk about what it takes to be a good contractor. Check out our latest episodes ',
      podcastStatDescriptionLink: 'here!',
      socialShareHeaderBasic:
        'Want a personalized landing page with your company’s stats next year? Upgrade to Pro or Premium on your ',
      socialShareHeaderBasicLink: 'billing page!',
      socialShareHeader: 'Share your year!',
      socialShareBody:
        'Show off what your business accomplished in 2024. Make sure to tag us in your post!',
      socialShareWarn:
        'Heads up—sharing this will generate a public link. Whoever has access to the link will also have access to your stats!',
      shareCTA: 'Share to Socials',
    },
    navList: {
      projectsCreated: 'PROJECTS CREATED',
      photosTaken: 'PHOTOS TAKEN',
      videosCaptured: 'VIDEOS CAPTURED',
      galleriesMade: 'GALLERIES MADE',
      pagesMade: 'PAGES MADE',
      checklistsMade: 'CHECKLISTS MADE',
    },
    statsHeaders: {
      header: 'YEAR IN REVIEW',
      photosTaken: 'Photos Taken',
      projectsCreated: 'Projects Created',
      videosCaptured: 'Videos Captured',
      galleriesMade: 'Galleries Made',
      pagesMade: 'Pages Made',
      checklistsCreated: 'Checklists Created',
    },
  },
  portfolio: {
    showcase: {
      emptyTitle: 'Ready to show off some work?',
      emptySubtitle:
        'Boost visibility and win more clients by publishing showcases to Trusty and your website.',
      trustyLive: 'Your Trusty profile is live!',
    },
    profile: {
      changeProfilePhoto: 'Change Profile Photo',
      emptyTitle: 'Create a Trusty profile to access your portfolio.',
      emptySubtitle:
        'The information in your profile helps customers find you on Google and Trusty.',
      setupTitle: 'Use your work to grow your business.',
      setupSubtitle: 'Setup your Trusty profile to get the following:',
      subheader2: 'Tell customers where you’re located and how to contact you.',
      subheader3:
        'Help us categorize your business so customers can search for you.',
      companyName: 'Company Name',
      address1: 'Address 1',
      address2: 'Address 2',
      industries: 'Industries',
      serviceAreas: 'Service Areas',
      serviceAreasPlaceholder: 'What cities would you like to be listed in?',
      setupStep2: 'Profile Setup (1/2)',
      setupStep3: 'Profile Setup (2/2)',
      valueProp1: 'Higher placement on Google',
      valueProp2: 'A sharable gallery of your best work',
      valueProp3: 'A premium Trusty listing in your service areas',
      searchGoogle: 'Enter business name or address to save time.',
      searchGoogleMessage: 'This is not needed to continue.',
      website: 'Website',
      validation: {
        companyName: 'Company name is required.',
        invalidPhone: 'Invalid phone number.',
        invalidEmail: 'Invalid email address.',
        industries: 'At least 1 industry is required.',
        serviceAreas: 'At least 1 service area is required.',
        phoneOrEmail: 'Either phone number or email is required.',
      },
      createSuccess: 'Public profile created successfully',
      createError: 'Error creating public profile',
      updateSuccess: 'Public profile updated successfully',
      updateError: 'Error updating public profile',
      editModalTitle: 'Edit Trusty Profile',
      companyNamePlaceholder: 'My Company',
      address1Placeholder: '123 Main St',
      address2Placeholder: 'Suite 100',
    },
    addToWebsite: 'Add to Website',
    addShowcase: 'Add Showcase',
    editProfile: 'Edit Profile',
    projectSuggestions: {
      title: 'Are these projects ready to showcase?',
    },
  },
};
